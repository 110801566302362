<template>
  <div id="handMadeFrom">
    <div id="box">
      <div class="ManageTop">
        <div class="title">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px; margin-right: 5px" />
          录入信息
        </div>
        <div class="hardTop">
          <div>
            <span class="TitleText">客户名称</span>
            <span class="ml-2">
              {{ this.$route.query.name }}
            </span>
          </div>
          <div class="box">
            <span class="TitleText">报表类型</span>
            <el-select disabled v-model="BType" size="small">
              <el-option v-for="item in pageTypeList" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </div>
          <div class="box">
            <span class="TitleText">报表日期</span>
            <el-date-picker disabled :value-format="BType == '00' ? 'yyyy' : 'yyyy-MM'" size="small" v-model="dateTime"
              :type="BType == '00' ? 'year' : 'month'" :popper-append-to-body="false">
            </el-date-picker>
          </div>
          <div class="box">
            <span class="TitleText">报表种类</span>
            <el-select disabled v-model="codetype" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="ManageBottom">
        <!-- 本部报表 -->
        <div class="elselect" v-show="$route.query.type == '3'">
          <span class="mr-1" style="font-size:14px">修改时间 </span>
          <el-select v-model="editTime" size="small" style="width:170px" @change="editTimeChange">
            <el-option v-for="item in editTimeData" :key="item.log_id"
              :label="item.modified_at.slice(0, 10) + ' ' + item.modified_at.slice(11, 16)" :value="item.log_id">
            </el-option>
          </el-select>
        </div>
        <el-tabs v-show="codetype == '01'" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="资产负债表" name="0">
            <el-form ref="form" label-width="80px">
              <div class="row">
                <el-table :data="balanceSheet[0]" :summary-method="getSummariesOne" show-summary
                  style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name" label="资产"></el-table-column>
                  <el-table-column prop="value2" label="年初余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataSheetType"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1" label="期末余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>

                <el-table :data="balanceSheet[1]" :summary-method="getSummariesTwo" show-summary
                  style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name" label="负债和所有者权益(或股东权益)"></el-table-column>
                  <el-table-column prop="value2" label="年初余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataSheetType"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1" label="期末余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="row tablesBox">
                <div style="width: 50%">
                  <el-table :data="balanceSheet[2]" :summary-method="getSummariesThree" show-summary
                    style="color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        height: '30px',
                      }">
                    <el-table-column prop="name"> </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataSheetType"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>

                  <div class="count">
                    <span style="flex: 1">资产总计</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentAssets[1]) + Number(FlagTotalCurrentAssets[1])) || "0.00"
                    }}</span>
                    <span style="flex: 1">{{ numFilter(
                      Number(TotalCurrentAssets[2]) + Number(FlagTotalCurrentAssets[2])) || "0.00"
                    }}</span>
                  </div>
                </div>
                <div style="width: 50%">
                  <el-table :data="balanceSheet[3]" :summary-method="getSummariesFour" show-summary
                    style="color: #333; font-size: 14px" :header-cell-style="{
                          background: '#E9F3FF',
                          color: '#333',
                          height: '2px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span v-if="row.code == '1920n'">
                          &emsp;&emsp;&emsp;{{ row.name }}
                        </span>
                        <span v-else>
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataSheetType"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>

                  <div class="count">
                    <span style="flex: 1">负债合计</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentLiability[1]) + Number(FlagTotalCurrentLiability[1])) ||
                      "0.00"
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentLiability[2]) + Number(FlagTotalCurrentLiability[2])) ||
                      "0.00"
                    }}</span>
                  </div>
                  <el-table :data="balanceSheet[4]" :summary-method="getSummariesFive" show-summary
                    style="color: #333; font-size: 14px" :header-cell-style="{
                          background: '#E9F3FF',
                          color: '#333',
                          height: '52px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span v-if="row.code == '1428n'">
                          &emsp;&emsp;&emsp;{{ row.name }}
                        </span>
                        <span v-else>
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataSheetType"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="count">
                    <span style="flex: 1">负债和所有者权益(或股东权益)合计</span>
                    <span style="flex: 1">{{
                      numFilter(Number(TotalCurrentLiability[1]) +
                        Number(FlagTotalCurrentLiability[1]) +
                        Number(ownershipInterest[1]))
                      || "0.00"
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentLiability[2]) +
                        Number(FlagTotalCurrentLiability[2]) +
                        Number(ownershipInterest[2])) || "0.00"
                    }}</span>
                  </div>
                </div>
              </div>
            </el-form>
            <div class="text-center mt-5" v-show="disabledType == '0'">
              <el-button size="small" @click="reset(1)"
                style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px">重 置</el-button>
              <el-tooltip class="item" effect="dark" content="数据仅保存24小时，过期则被清除，请及时提交" placement="top">
                <el-button size="small" @click="present(1)" type="primary"
                  style="width: 120px; font-size: 14px">保存当前页面</el-button>
              </el-tooltip>
            </div>
          </el-tab-pane>
          <el-tab-pane label="利润表" name="1">
            <el-form ref="form" label-width="80px">
              <el-table :data="tableDataProfit[0]" :summary-method="getSummariesSix" show-summary
                style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                <el-table-column prop="name" label="项目">
                  <template slot-scope="{ row }">
                    <span v-if="row.code == '1080' || row.code == '1081j' || row.code == '1669'">{{ row.name }}</span>
                    <span v-else>&emsp;&emsp;{{ row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="value1" label="本期金额(累计数)">
                  <template slot-scope="{ row }">
                    <el-form-item>
                      <el-input-number @blur="blur_lr" @keydown.enter.native="nextFocus($event)" :precision="2"
                        :min="-Infinity" :max="Infinity" :controls="false"
                        :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                        style="width: 80%" v-model="row.value1"></el-input-number>
                    </el-form-item>
                    <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                      <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="上期金额(累计数)">
                  <template slot-scope="{ row }">
                    <el-form-item>
                      <el-input-number @blur="blur_lr" @keydown.enter.native="nextFocus($event)" :precision="2"
                        :min="-Infinity" :max="Infinity" :controls="false"
                        :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                        style="width: 80%" v-model="row.value2"></el-input-number>
                    </el-form-item>
                    <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tablesBox">
                <!-- :summary-method="getSummariesSeven" show-summary -->
                <el-table :data="tableDataProfit[1]" ref="lrze" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="blur_lr" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="blur_lr" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">三、利润总额（亏损总额以“-”号填列）</span>
                  <span style="flex: 1">{{
                    TotalProfit[1] || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    TotalProfit[2] || "0.00"
                  }}</span>
                </div>
                <!-- :summary-method="getSummarieset" show-summary -->
                <el-table :data="tableDataProfit[2]" ref="jlr" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="blur_lr" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="blur_lr" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">四、净利润（净亏损以“-”号填列）</span>
                  <span style="flex: 1">{{
                    retained[1] || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    retained[2] || "0.00"
                  }}</span>
                </div>
                <el-table :data="tableDataProfit[3]" style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">五、其他综合收益的税后净额</span>
                  <span style="flex: 1">{{
                    numFilter(Number(statement[1]) + Number(reclassification[1])) || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(Number(statement[2]) + Number(reclassification[2])) || "0.00"
                  }}</span>
                </div>
                <div class="contentInfoWrap">
                  <el-table :data="tableDataProfit[4]" :summary-method="getWrap" show-summary
                    style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                          background: '#E9F3FF',
                          color: '#333',
                          height: '48px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span>&emsp;&emsp;{{ row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-table :data="tableDataProfit[5]" :summary-method="getSummarieWrap" show-summary
                    style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#FFF',
                        color: '#333',
                        height: '48px',
                      }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span>&emsp;&emsp;{{ row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="count">
                  <span style="flex: 1">六、综合收益总额</span>
                  <span style="flex: 1">{{
                    numFilter(Number(statement[1]) + Number(reclassification[1]) + Number(retained[1]))
                    || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(Number(statement[2]) + Number(reclassification[2]) + Number(retained[2]))
                    || "0.00"
                  }}</span>
                </div>
                <el-table :data="tableDataProfit[6]" style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <span v-if="row.name == '七、每股收益'" :style="row.name == '七、每股收益' ? { fontWeight: 550 } : ''">
                        {{ row.name }}
                      </span>
                      <span v-else>
                        {{ row.name }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '七、每股收益'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '七、每股收益'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form>
            <div class="text-center mt-5" v-show="disabledType == '0'">
              <el-button size="small" @click="reset(2)"
                style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px">重 置</el-button>
              <el-tooltip class="item" effect="dark" content="数据仅保存24小时，过期则被清除，请及时提交" placement="top">
                <el-button size="small" @click="present(2)" type="primary"
                  style="width: 120px; font-size: 14px">保存当前页面</el-button>
              </el-tooltip>
            </div>
          </el-tab-pane>
          <el-tab-pane label="现金流量表" name="2">
            <el-form ref="form" label-width="80px">
              <el-table :data="tableDataCash[0]" :summary-method="getActivities" show-summary
                style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                    background: '#E9F3FF',
                    color: '#333',
                    height: '48px',
                  }">
                <el-table-column prop="name" label="项目"></el-table-column>
                <el-table-column prop="value1" label="本期金额(累计数)">
                  <template slot-scope="{ row }">
                    <div v-if="row.name == '一、经营活动产生的现金流量：'" style="height: 41px"></div>
                    <el-form-item v-else>
                      <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                        :min="-Infinity" :max="Infinity" :controls="false"
                        :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                        style="width: 80%" v-model="row.value1"></el-input-number>
                    </el-form-item>
                    <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                      <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="上期金额(累计数)">
                  <template slot-scope="{ row }">
                    <div v-if="row.name == '一、经营活动产生的现金流量：'" style="height: 41px"></div>
                    <el-form-item v-else>
                      <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                        :min="-Infinity" :max="Infinity" :controls="false"
                        :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                        style="width: 80%" v-model="row.value2"></el-input-number>
                    </el-form-item>
                    <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tablesBox">
                <el-table :data="tableDataCash[1]" :summary-method="getActivitiesOut" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">经营活动产生的现金流量净额</span>
                  <span style="flex: 1">{{
                    numFilter(Number(getActivitiesTotal[1]) - Number(getActivitiesTotalOut[1])) || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(Number(getActivitiesTotal[2]) - Number(getActivitiesTotalOut[2])) || "0.00"
                  }}</span>
                </div>
                <el-table :data="tableDataCash[2]" :summary-method="investmentTotal" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '二、投资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '二、投资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>

                <el-table :data="tableDataCash[3]" :summary-method="investmentTotalOut" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">投资活动产生的现金流量净额</span>
                  <span style="flex: 1">{{
                    numFilter(Number(investmentTotals[1]) - Number(investmentOut[1])) || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(Number(investmentTotals[2]) - Number(investmentOut[2])) || "0.00"
                  }}</span>
                </div>
                <el-table :data="tableDataCash[4]" :summary-method="getSummariesRaise" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '三、筹资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '三、筹资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="tableDataCash[5]" :summary-method="getSummariesRaiseOut" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">筹资活动产生的现金流量净额</span>
                  <span style="flex: 1">{{
                    numFilter(Number(getSummariesTotal[1]) - Number(getSummariesOut[1])) || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(Number(getSummariesTotal[2]) - Number(getSummariesOut[2])) || "0.00"
                  }}</span>
                </div>
                <!-- :summary-method="geTexchange" show-summary -->
                <el-table :data="tableDataCash[6]" ref="zje" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div style="font-weight: 550;">
                        {{ row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">五、现金及现金等价物净增加额</span>
                  <span style="flex: 1">{{
                    getTexchange[1]
                  }}</span>
                  <span style="flex: 1">{{
                    getTexchange[2]
                  }}</span>
                </div>
                <!-- :summary-method="geTexchangesa" show-summary -->
                <el-table :data="tableDataCash[7]" ref="ye" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_zje" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">六、期末现金及现金等价物余额</span>
                  <span style="flex: 1">{{
                    getTexchanges[1] || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    getTexchanges[2] || "0.00"
                  }}</span>
                </div>
                <div class="count">
                  <span style="flex: 1">补充材料</span>
                  <span style="flex: 1"></span>
                  <span style="flex: 1"></span>
                </div>
                <el-table :data="tableDataCash[8]" :summary-method="supplement" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                        fontWeight: '600',
                      }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '1、将净利润调节为经营活动现金流量'" style="height: 41px;font-weight:600;padding-top:10px">
                        {{ row.name }}
                      </div>
                      <div v-else style="height: 41px;padding-top:10px">
                        {{ row.name }}
                      </div>

                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '1、将净利润调节为经营活动现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '1、将净利润调节为经营活动现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="tableDataCash[9]" style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                    background: '#E9F3FF',
                    color: '#333',
                    height: '48px',
                    fontWeight: '600',
                  }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '2、不涉及现金收支的投资和筹资活动'" style="height: 41px;font-weight: 600;padding-top:10px">
                        {{ row.name }}
                      </div>
                      <div v-else style="height: 41px;padding-top:10px">
                        {{ row.name }}
                      </div>

                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '2、不涉及现金收支的投资和筹资活动'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '2、不涉及现金收支的投资和筹资活动'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>

                <el-table :data="tableDataCash[10]" :summary-method="supplements" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#FFF',
                      color: '#333',
                      height: '48px',
                      fontWeight: '600',
                    }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '3、现金及现金等价物净增加情况'" style="height: 41px;font-weight: 600;padding-top:10px">
                        {{ row.name }}
                      </div>
                      <div v-else style="height: 41px;padding-top:10px">
                        {{ row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '3、现金及现金等价物净增加情况'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '3、现金及现金等价物净增加情况'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form>
            <div class="text-center mt-5" v-show="disabledType == '0'">
              <el-button size="small" @click="reset(3)"
                style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px">重 置</el-button>
              <el-tooltip class="item" effect="dark" content="数据仅保存24小时，过期则被清除，请及时提交" placement="top">
                <el-button size="small" @click="present(3)" type="primary"
                  style="width: 120px; font-size: 14px">保存当前页面</el-button>
              </el-tooltip>
            </div>
          </el-tab-pane>
        </el-tabs>
        <!-- 合并报表 -->
        <el-tabs v-show="codetype == '00'" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="资产负债表" name="0">
            <el-form ref="form" label-width="80px">
              <div class="row">
                <!-- ----流动资产：---- -->
                <el-table :data="balanceSheetMerge[0]" :summary-method="getSummariesOnes" show-summary
                  style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name" label="资产"></el-table-column>
                  <el-table-column prop="value2" label="年初余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataSheetType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1" label="期末余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- ----流动负债：---- -->
                <el-table :data="balanceSheetMerge[1]" :summary-method="getSummariesTwos" show-summary
                  style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name" label="负债和所有者权益(或股东权益)"></el-table-column>
                  <el-table-column prop="value2" label="年初余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataSheetType == true || tableDataSheetType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1" label="期末余额(元)">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                        </el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="row tablesBox">
                <div style="width: 50%">
                  <el-table :data="balanceSheetMerge[2]" :summary-method="getSummariesThrees" show-summary
                    style="color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                    <el-table-column prop="name"> </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataSheetType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- 资产总计 -->
                  <div class="count">
                    <span style="flex: 1">资产总计</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentAssetss[1]) + Number(FlagTotalCurrentAssetss[1])
                      ) || "0.00"
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentAssetss[2]) + Number(FlagTotalCurrentAssetss[2])
                      ) || '0.00'
                    }}</span>
                  </div>
                </div>
                <div style="width: 50%">
                  <el-table :data="balanceSheetMerge[3]" :summary-method="getSummariesFours" show-summary
                    style="color: #333; font-size: 14px" :header-cell-style="{
                          background: '#E9F3FF',
                          color: '#333',
                          height: '48px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span v-if="row.code == '1920n'">
                          &emsp;&emsp;&emsp;{{ row.name }}
                        </span>
                        <span v-else>
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataSheetType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- 负债总合计 -->
                  <div class="count">
                    <span style="flex: 1">负债合计</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentLiabilitys[1]) +
                        Number(FlagTotalCurrentLiabilitys[1])
                      ) || "0.00"
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentLiabilitys[2]) +
                        Number(FlagTotalCurrentLiabilitys[2])
                      ) || "0.00"
                    }}</span>
                  </div>
                  <el-table :data="balanceSheetMerge[4]" ref="syzqy" :summary-method="getSummariesFives" show-summary
                    style="color: #333; font-size: 14px" :header-cell-style="{
                          background: '#E9F3FF',
                          color: '#333',
                          height: '52px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span v-if="row.code == '1428n'">
                          &emsp;&emsp;&emsp;{{ row.name }}
                        </span>
                        <span v-else :style="row.code == '1430h' ? { fontWeight: 550 } : ''">
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number v-if="row.code !== '1430h'" @blur="qyhj"
                            @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity" :max="Infinity"
                            :controls="false" :disabled="disabledType !== '0' || tableDataSheetType == true"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2">
                          </el-input-number>
                          <div v-else style="font-weight:550">
                            <span>{{ parseFloat(row.value2).toFixed(2) }} </span>
                            <!-- <span v-show="row.value2==''||row.value2=='0'">0.00</span> -->
                          </div>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                        <el-form-item v-else>
                          <el-input-number v-if="row.code !== '1430h'" @blur="qyhj"
                            @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity" :max="Infinity"
                            :controls="false" :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入"
                            size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                          <div v-else style="font-weight:550">
                            <span>{{ parseFloat(row.value1).toFixed(2) }} </span>
                            <!-- <span v-show="row.value1==''||row.value1==0">0.00</span> -->
                          </div>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="count">
                    <span style="flex: 1">负债和所有者权益(或股东权益)合计</span>
                    <span style="flex: 1">{{
                      numFilter(Number(TotalCurrentLiabilitys[1]) +
                        Number(FlagTotalCurrentLiabilitys[1]) +
                        Number(ownershipInterests[1]))
                      || "0.00"
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(
                        Number(TotalCurrentLiabilitys[2]) +
                        Number(FlagTotalCurrentLiabilitys[2]) +
                        Number(ownershipInterests[2])) || "0.00"
                    }}</span>
                  </div>
                </div>
              </div>
            </el-form>
            <div class="text-center mt-5" v-show="disabledType == '0'">
              <el-button size="small" @click="reset(1)" style="width: 120px; font-size: 14px">重 置</el-button>
              <el-tooltip class="item" effect="dark" content="数据仅保存24小时，过期则被清除，请及时提交" placement="top">
                <el-button size="small" @click="present(1)" type="primary"
                  style="width: 120px; font-size: 14px">保存当前页面</el-button>
              </el-tooltip>
            </div>
          </el-tab-pane>
          <el-tab-pane label="利润表" name="1">
            <el-form ref="form" label-width="80px">
              <div class="contentInfoWrap">
                <el-table :data="tableDataProfitMerge[0]" :summary-method="getSummariesSixs" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                          background: '#E9F3FF',
                          color: '#333',
                          height: '48px',
                        }">
                  <el-table-column prop="name" label="项目">
                    <template slot-scope="{ row }">
                      <span v-if="row.code == '1080'">{{ row.name }}</span>
                      <span v-else>&emsp;&emsp;&emsp;{{ row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1" label="本期金额(累计数)">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2" label="上期金额(累计数)">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="tablesBox">
                <div class="contentInfoWrap">
                  <el-table ref="tableDataProfitMerge" :data="tableDataProfitMerge[1]"
                    :summary-method="getSummariesSevens" show-summary style="width: 100%; color: #333; font-size: 14px"
                    :header-cell-style="{
                        background: '#FFF',
                        color: '#333',
                        height: '48px',
                      }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span v-if="row.code == '1081j'">{{ row.name }}</span>
                        <span v-else>&emsp;&emsp;&emsp;{{ row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                            :min="-Infinity" :max="Infinity" :controls="false"
                            :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value1"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                            :min="-Infinity" :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- :summary-method="getSummariesets" show-summary -->
                <el-table :data="tableDataProfitMerge[2]" ref="yylr" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <span v-if="row.code == '1669'">{{ row.name }}</span>
                      <span v-else>&emsp;&emsp;&emsp;{{ row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">三、营业利润</span>
                  <span style="flex: 1">{{
                    retaineds[1] || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    retaineds[2] || "0.00"
                  }}</span>
                </div>
                <!-- :summary-method="getWraps" show-summary -->
                <el-table :data="tableDataProfitMerge[3]" ref="lrze_three"
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">四、利润总额（亏损总额以“-”号填列）</span>
                  <span style="flex: 1">{{
                    statements[1] || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    statements[2] || "0.00"
                  }}</span>
                </div>
                <!-- :summary-method="getSummarieWraps" show-summary -->
                <el-table :data="tableDataProfitMerge[4]" ref="jlr_h" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="SummarBlur" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">五、净利润（净亏损以“-”号填列）</span>
                  <span style="flex: 1">{{
                    statementsact[1] || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    statementsact[2] || "0.00"
                  }}</span>
                </div>


                <div class="contentInfoWrap">
                  <el-table :data="tableDataProfitMerge[5]" :summary-method="getSummarieClass" show-summary
                    style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                          background: '#FFF',
                          color: '#333',
                          height: '48px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span>&emsp;&emsp;
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2"></el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>

                  <el-table :data="tableDataProfitMerge[6]" :summary-method="getSummarieClasss" show-summary
                    style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#FFF',
                        color: '#333',
                        height: '48px',
                      }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span>&emsp;&emsp;
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="count">
                    <span style="flex: 1">六、其他综合收益的税后净额</span>
                    <span style="flex: 1">{{
                      numFilter(Number(noClass[1]) + Number(noClasss[1])) || "0.00"
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(Number(noClass[2]) + Number(noClasss[2])) || "0.00"
                    }}</span>
                  </div>
                  <div class="count">
                    <span style="flex: 1">（一）归属于母公司所有者的其他综合收益的税后净额</span>
                    <span style="flex: 1">{{
                      numFilter(Number(noClass[1]) + Number(noClasss[1])) || "0.00"
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(Number(noClass[2]) + Number(noClasss[2])) || "0.00"
                    }}</span>
                  </div>
                  <el-table :data="tableDataProfitMerge[7]" :summary-method="getNoClass" show-summary
                    style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                          background: '#FFF',
                          color: '#333',
                          height: '48px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span>&emsp;
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-table :data="tableDataProfitMerge[8]" :summary-method="getNoClasss" show-summary
                    style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#FFF',
                        color: '#333',
                        height: '48px',
                      }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <span>&emsp;
                          {{ row.name }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="count">
                    <span style="flex: 1">七、综合收益总额</span>
                    <span style="flex: 1">{{
                      numFilter(Number(statementsact[1]) + Number(noClass[1]) + Number(noClasss[1])) ||
                      '0.00'
                    }}</span>
                    <span style="flex: 1">{{
                      numFilter(Number(statementsact[2]) + Number(noClass[2]) + Number(noClasss[2])) ||
                      '0.00'
                    }}</span>
                  </div>
                  <el-table :data="tableDataProfitMerge[9]" style="width: 100%; color: #333; font-size: 14px"
                    :header-cell-style="{
                          background: '#E9F3FF',
                          color: '#333',
                          height: '48px',
                        }">
                    <el-table-column prop="name">
                      <template slot-scope="{ row }">
                        <div :style="row.name == '八、每股收益' ? { fontWeight: 550 } : ''">
                          {{ row.name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value1">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                            placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                          <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value2">
                      <template slot-scope="{ row }">
                        <el-form-item>
                          <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                            :max="Infinity" :controls="false"
                            :disabled="disabledType !== '0' || tableDataProfitType == true" placeholder="请输入" size="mini"
                            style="width: 80%" v-model="row.value2">
                          </el-input-number>
                        </el-form-item>
                        <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-form>
            <div class="text-center mt-5" v-show="disabledType == '0'">
              <el-button size="small" @click="reset(2)" style="width: 120px; font-size: 14px">重 置</el-button>
              <el-tooltip class="item" effect="dark" content="数据仅保存24小时，过期则被清除，请及时提交" placement="top">
                <el-button size="small" @click="present(2)" type="primary"
                  style="width: 120px; font-size: 14px">保存当前页面</el-button>
              </el-tooltip>

            </div>
          </el-tab-pane>
          <el-tab-pane label="现金流量表" name="2">
            <el-form ref="form" label-width="80px">
              <el-table :data="tableDataCashMerge[0]" :summary-method="getActivitiess" show-summary
                style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                    background: '#E9F3FF',
                    color: '#333',
                    height: '48px',
                  }">
                <el-table-column prop="name" label="项目"></el-table-column>
                <el-table-column prop="value1" label="本期金额(累计数)">
                  <template slot-scope="{ row }">
                    <div v-if="row.name == '一、经营活动产生的现金流量'" style="height: 41px"></div>
                    <el-form-item v-else>
                      <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                        :min="-Infinity" :max="Infinity" :controls="false"
                        :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                        style="width: 80%" v-model="row.value1"></el-input-number>
                    </el-form-item>
                    <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                      <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="value2" label="上期金额(累计数)">
                  <template slot-scope="{ row }">
                    <div v-if="row.name == '一、经营活动产生的现金流量'" style="height: 41px"></div>
                    <el-form-item v-else>
                      <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                        :min="-Infinity" :max="Infinity" :controls="false"
                        :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                        style="width: 80%" v-model="row.value2"></el-input-number>
                    </el-form-item>
                    <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tablesBox">
                <el-table :data="tableDataCashMerge[1]" :summary-method="getActivitiesOuts" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">经营活动产生的现金流量净额</span>
                  <span style="flex: 1">{{
                    numFilter(
                      Number(getActivitiesTotals[1]) - Number(getActivitiesTotalOuts[1])
                    ) || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(
                      Number(getActivitiesTotals[2]) - Number(getActivitiesTotalOuts[2])
                    ) || "0.00"
                  }}</span>
                </div>
                <el-table :data="tableDataCashMerge[2]" :summary-method="investmentTotalty" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '二、投资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '二、投资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>

                <el-table :data="tableDataCashMerge[3]" :summary-method="investmentTotalOuts" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">投资活动产生的现金流量净额</span>
                  <span style="flex: 1">{{
                    numFilter(Number(investmentTotalss[1]) - Number(investmentOuts[1])) || "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(Number(investmentTotalss[2]) - Number(investmentOuts[2])) || "0.00"
                  }}</span>
                </div>

                <el-table :data="tableDataCashMerge[4]" :summary-method="getSummariesRaises" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '三、筹资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '三、筹资活动产生的现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="tableDataCashMerge[5]" :summary-method="getSummariesRaiseOuts" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">筹资活动产生的现金流量净额</span>
                  <span style="flex: 1">{{ numFilter(Number(getSummariesTotals[1]) - Number(getSummariesOuts[1])) ||
                    "0.00"
                  }}</span>
                  <span style="flex: 1">{{
                    numFilter(Number(getSummariesTotals[2]) - Number(getSummariesOuts[2])) ||
                    "0.00"
                  }}</span>
                </div>
                <!-- :summary-method="geTexchanges" show-summary -->
                <el-table :data="tableDataCashMerge[6]" ref="wu_zje" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                        background: '#E9F3FF',
                        color: '#333',
                        height: '48px',
                      }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div style="font-weight:550">
                        {{ row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">五、现金及现金等价物净增加额</span>
                  <span style="flex: 1">
                    {{ getTexchanges[1] || '0.00' }}
                  </span>
                  <span style="flex: 1">
                    {{ getTexchanges[2] || '0.00' }}
                  </span>
                </div>
                <!-- :summary-method="geTexchangess" show-summary -->
                <el-table :data="tableDataCashMerge[7]" ref="qcye" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                    }">
                  <el-table-column prop="name"></el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType == '1' || disabledType == '3'" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <el-form-item>
                        <el-input-number @blur="xjl_bl" @keydown.enter.native="nextFocus($event)" :precision="2"
                          :min="-Infinity" :max="Infinity" :controls="false"
                          :disabled="disabledType !== '0' || tableDataCashType == true" placeholder="请输入" size="mini"
                          style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="count">
                  <span style="flex: 1">六、期末现金及现金等价物余额</span>
                  <span style="flex: 1">
                    {{ getTexchangess[1] || "0.00" }}
                  </span>
                  <span style="flex: 1">
                    {{ getTexchangess[2] || "0.00" }}
                  </span>
                </div>
                <div class="count">
                  <span style="flex: 1">补充材料</span>
                  <span style="flex: 1"></span>
                  <span style="flex: 1"></span>
                </div>
                <el-table :data="tableDataCashMerge[8]" :summary-method="supplement" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                      fontWeight: '600',
                    }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '1、将净利润调节为经营活动现金流量'" style="height: 41px;font-weight:600;padding-top:10px">
                        {{ row.name }}
                      </div>
                      <div v-else style="height: 41px;padding-top:10px">
                        {{ row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '1、将净利润调节为经营活动现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '1、将净利润调节为经营活动现金流量'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="tableDataCashMerge[9]" style="width: 100%; color: #333; font-size: 14px"
                  :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                      height: '48px',
                      fontWeight: '600',
                    }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '2、不涉及现金收支的投资和筹资活动'" style="height: 41px;font-weight:600;padding-top:10px">
                        {{ row.name }}
                      </div>
                      <div v-else style="height: 41px;padding-top:10px">
                        {{ row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '2、不涉及现金收支的投资和筹资活动'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '2、不涉及现金收支的投资和筹资活动'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="tableDataCashMerge[10]" :summary-method="supplements" show-summary
                  style="width: 100%; color: #333; font-size: 14px" :header-cell-style="{
                      background: '#FFF',
                      color: '#333',
                      height: '48px',
                      fontWeight: '600',
                    }">
                  <el-table-column prop="name">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '3、现金及现金等价物净增加情况'" style="height: 41px;font-weight:600;padding-top:10px">
                        {{ row.name }}
                      </div>
                      <div v-else style="height: 41px;padding-top:10px">
                        {{ row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value1">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '3、现金及现金等价物净增加情况'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType == '1' || disabledType == '3'"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">
                        <span v-show="row.before_mod_cur !== undefined">变更前：{{ row.before_mod_cur }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value2">
                    <template slot-scope="{ row }">
                      <div v-if="row.name == '3、现金及现金等价物净增加情况'" style="height: 41px"></div>
                      <el-form-item v-else>
                        <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2" :min="-Infinity"
                          :max="Infinity" :controls="false" :disabled="disabledType !== '0' || tableDataCashType == true"
                          placeholder="请输入" size="mini" style="width: 80%" v-model="row.value2"></el-input-number>
                      </el-form-item>
                      <div style="padding:0px;color:#F2637B;font-size: 12px;height:20px" v-show="disabledType == '3'">

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form>
            <div class="text-center mt-5" v-show="disabledType == '0'">
              <el-button size="small" @click="reset(3)" style="width: 120px; font-size: 14px">重 置</el-button>
              <el-tooltip class="item" effect="dark" content="数据仅保存24小时，过期则被清除，请及时提交" placement="top">
                <el-button size="small" @click="present(3)" type="primary"
                  style="width: 120px; font-size: 14px">保存当前页面</el-button>
              </el-tooltip>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="flexBottom">
      <el-button size="small" @click="back" style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px">取
        消</el-button>
      <el-tooltip class="item" effect="dark" content="建议在三表页面均保存后提交三表数据" placement="top">
        <el-button size="small" type="primary" v-show="disabledType == '0' || disabledType == '2'"
          @click="dialogVisible = true" style="width: 120px; font-size: 14px">提交三表数据</el-button>
      </el-tooltip>
    </div>
    <!-- 上传表提示框 -->
    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <div class="text-center">
        <p style="font-size: 16px; color: #333">是否确认提交三表数据？</p>
        <!-- <p style="font-size: 14px; color: #999">
          注：当确认提交数据，将扣减一次修改次数
        </p> -->
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px"
          @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" style="width:120px;font-size:14px" type="primary" @click="onDialogVisible">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="温馨提示" :visible.sync="localShow" width="30%" :close-on-click-modal="false">
      <div class="text-center">
        <p style="font-size: 16px; color: #333">系统中有尚未完成的已保存报表，是否读取数据？</p>
        <p style="font-size: 16px; color: #999">注：点击 "取消" 将重新填写</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px"
          @click="localShow = false">取 消</el-button>
        <el-button size="small" style="width:120px;font-size:14px" type="primary" @click="onlocalShow">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="温馨提示" :visible.sync="sheet" width="30%" :close-on-click-modal="false">
      <div>
        因本次录入了二期数值，除生成本年报表外，系统还会自动生成上年报表。但上年报表的资产负债表年初数目前为缺失，请点击确定进行补充录入。如果不填列，可能导致后续分析结果不够准确。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px"
          @click="back">不填列</el-button>
        <el-button size="small" style="width:120px;font-size:14px" type="primary" @click="onSheet">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { hand_save, getData, hand_save_read, hand_save_cache, change_log, change_logFs } from "@/api";
import * as sesstion from "@/store/localStorage";
export default {
  name: "handMadeFrom",
  data() {
    return {
      inputDoms: '',
      nextNum: 0,
      // localShow:true,
      // 资产负债表
      st: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      balanceSheet: [
        // 资产负债表--流动资产
        [
          {
            name: "流动资产：",
          },
          {
            name: "货币资金",
            value1: "",
            value2: "",
            code: "1001",
          },
          {
            name: "交易性金融资产",
            value1: "",
            value2: "",
            code: "1239",
          },
          {
            name: "衍生金融资产",
            value1: "",
            value2: "",
            code: "1411",
          },
          {
            name: "应收票据",
            value1: "",
            value2: "",
            code: "1003",
          },
          {
            name: "应收账款",
            value1: "",
            value2: "",
            code: "1008",
          },
          {
            name: "应收款项融资",
            value1: "",
            value2: "",
            code: "1657",
          },
          {
            name: "预付款项",
            value1: "",
            value2: "",
            code: "1009",
          },
          {
            name: "其他应收款",
            value1: "",
            value2: "",
            code: "1210",
          },
          {
            name: "存货",
            value1: "",
            value2: "",
            code: "1014",
          },
          {
            name: "合同资产",
            value1: "",
            value2: "",
            code: "1658",
          },
          {
            name: "持有待售资产",
            value1: "",
            value2: "",
            code: "1821",
          },
          {
            name: "一年内到期的非流动资产",
            value1: "",
            value2: "",
            code: "1241",
          },
          {
            name: "其他流动资产",
            value1: "",
            value2: "",
            code: "1213",
          },
        ],
        // 资产负债表--流动负债
        [
          {
            name: "流动负债：",
          },
          {
            name: "短期借款",
            value1: "",
            value2: "",
            code: "1047",
          },
          {
            name: "交易性金融负债",
            value1: "",
            value2: "",
            code: "1255",
          },
          {
            name: "衍生金融负债",
            value1: "",
            value2: "",
            code: "1422",
          },
          {
            name: "应付票据",
            value1: "",
            value2: "",
            code: "1048",
          },
          {
            name: "应付账款",
            value1: "",
            value2: "",
            code: "1049",
          },
          {
            name: "预收款项",
            value1: "",
            value2: "",
            code: "1050",
          },
          {
            name: "合同负债",
            value1: "",
            value2: "",
            code: "1663",
          },
          {
            name: "应付职工薪酬",
            value1: "",
            value2: "",
            code: "1258",
          },
          {
            name: "应交税费",
            value1: "",
            value2: "",
            code: "1259",
          },
          {
            name: "其他应付款",
            value1: "",
            value2: "",
            code: "1056",
          },
          {
            name: "持有待售负债",
            value1: "",
            value2: "",
            code: "1696",
          },
          {
            name: "一年内到期的非流动负债",
            value1: "",
            value2: "",
            code: "1262",
          },
          {
            name: "其他流动负债",
            value1: "",
            value2: "",
            code: "1060",
          },
        ],
        // 资产负债表--非流动资产
        [
          {
            name: "非流动资产：",
          },
          {
            name: "债权投资",
            value1: "",
            value2: "",
            code: "1659",
          },
          {
            name: "其他债权投资",
            value1: "",
            value2: "",
            code: "1660",
          },
          {
            name: "长期应收款",
            value1: "",
            value2: "",
            code: "1244",
          },
          {
            name: "长期股权投资",
            value1: "",
            value2: "",
            code: "1245",
          },
          {
            name: "其他权益工具投资",
            value1: "",
            value2: "",
            code: "1661",
          },
          {
            name: "其他非流动金融资产",
            value1: "",
            value2: "",
            code: "1662",
          },
          {
            name: "投资性房地产",
            value1: "",
            value2: "",
            code: "1246",
          },
          {
            name: "固定资产",
            value1: "",
            value2: "",
            code: "1030",
          },
          {
            name: "在建工程",
            value1: "",
            value2: "",
            code: "1034",
          },
          {
            name: "生产性生物资产",
            value1: "",
            value2: "",
            code: "1247",
          },
          {
            name: "油气资产",
            value1: "",
            value2: "",
            code: "1248",
          },
          {
            name: "使用权资产",
            value1: "",
            value2: "",
            code: "1822",
          },
          {
            name: "无形资产",
            value1: "",
            value2: "",
            code: "1041",
          },
          {
            name: "开发支出",
            value1: "",
            value2: "",
            code: "1249",
          },
          {
            name: "商誉",
            value1: "",
            value2: "",
            code: "1250",
          },
          {
            name: "长期待摊费用",
            value1: "",
            value2: "",
            code: "1251",
          },
          {
            name: "递延所得税资产",
            value1: "",
            value2: "",
            code: "1252",
          },
          {
            name: "其他非流动资产",
            value1: "",
            value2: "",
            code: "1253",
          },
        ],
        // 资产负债表--非流动负债
        [
          {
            name: "非流动负债：",
          },
          {
            name: "长期借款",
            value1: "",
            value2: "",
            code: "1062",
          },
          {
            name: "应付债券",
            value1: "",
            value2: "",
            code: "1063",
          },
          {
            name: "其中：优先股",
            value1: "",
            value2: "",
            code: "1919n",
          },
          {
            name: "永续债",
            value1: "",
            value2: "",
            code: "1920n",
          },
          {
            name: "租赁负债",
            value1: "",
            value2: "",
            code: "1697",
          },
          {
            name: "长期应付款",
            value1: "",
            value2: "",
            code: "1064",
          },
          {
            name: "预计负债",
            value1: "",
            value2: "",
            code: "1263",
          },
          {
            name: "递延收益",
            value1: "",
            value2: "",
            code: "1611",
          },
          {
            name: "递延所得税负债",
            value1: "",
            value2: "",
            code: "1264",
          },
          {
            name: "其他非流动负债",
            value1: "",
            value2: "",
            code: "1265",
          },
        ],
        // 资产负债表--所有者权益
        [
          {
            name: "所有者权益(或股东权益)：",
          },
          {
            name: "实收资本(或股本)",
            value1: "",
            value2: "",
            code: "1071",
          },
          {
            name: "其他权益工具",
            value1: "",
            value2: "",
            code: "1268",
          },
          {
            name: "其中：优先股",
            value1: "",
            value2: "",
            code: "1427n",
          },
          {
            name: "永续债",
            value1: "",
            value2: "",
            code: "1428n",
          },
          {
            name: "资本公积",
            value1: "",
            value2: "",
            code: "1072",
          },
          {
            name: "减：库存股",
            value1: "",
            value2: "",
            code: "1269j",
          },
          {
            name: "其他综合收益",
            value1: "",
            value2: "",
            code: "1270",
          },
          {
            name: "专项储备",
            value1: "",
            value2: "",
            code: "1615",
          },
          {
            name: "盈余公积",
            value1: "",
            value2: "",
            code: "1073",
          },
          {
            name: "未分配利润",
            value1: "",
            value2: "",
            code: "1075",
          },
        ],
      ],
      // 利润表
      tableDataProfit: [
        [
          {
            name: "一、营业收入",
            value1: "",
            value2: "",
            code: "1080",
          },
          {
            name: "减：营业成本",
            value1: "",
            value2: "",
            code: "1081j",
          },
          {
            name: "税金及附加",
            value1: "",
            value2: "",
            code: "1082j",
          },
          {
            name: "销售费用",
            value1: "",
            value2: "",
            code: "1086j",
          },
          {
            name: "管理费用",
            value1: "",
            value2: "",
            code: "1087j",
          },
          {
            name: "研发费用",
            value1: "",
            value2: "",
            code: "1666j",
          },
          {
            name: "财务费用",
            value1: "",
            value2: "",
            code: "1088j",
          },
          {
            name: "其中：利息费用",
            value1: "",
            value2: "",
            code: "1088n",
          },
          {
            name: "利息收入",
            value1: "",
            value2: "",
            code: "1684n",
          },
          {
            name: "加：其他收益",
            value1: "",
            value2: "",
            code: "1669",
          },
          {
            name: "投资收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1281",
          },
          {
            name: "其中：对联营企业和合营企业的投资收益",
            value1: "",
            value2: "",
            code: "1282n",
          },
          {
            name: "以摊余成本计量的金融资产终止确认收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1670n",
          },
          {
            name: "净敞口套期收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1671",
          },
          {
            name: "公允价值变动收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1280",
          },
          {
            name: "信用减值损失（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1654j",
          },
          {
            name: "资产减值损失（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1279j",
          },
          {
            name: "资产处置收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1633j",
          },
        ],
        [
          {
            name: "加：营业外收入",
            value1: "",
            value2: "",
            code: "1093",
          },
          {
            name: "减：营业外支出",
            value1: "",
            value2: "",
            code: "1632j",
          },
        ],
        [
          {
            name: "减：所得税费用",
            value1: "",
            value2: "",
            code: "1161j",
          },
        ],
        [
          {
            name: "(一) 持续经营净利润（净亏损以“-”号填列）",
            value1: "",
            value2: "",
            code: "1825",
          },
          {
            name: "(二) 终止经营净利润（净亏损以“-”号填列）",
            value1: "",
            value2: "",
            code: "1826",
          },
        ],
        [
          {
            name: "1. 重新计量设定收益计划变动额",
            value1: "",
            value2: "",
            code: "1829",
          },
          {
            name: "2. 权益法下不能转损益的其他综合收益",
            value1: "",
            value2: "",
            code: "1830",
          },
          {
            name: "3. 其他权益工具投资公允价值变动",
            value1: "",
            value2: "",
            code: "1831",
          },
          {
            name: "4. 企业自身信用风险公允价值变动",
            value1: "",
            value2: "",
            code: "1832",
          },
        ],
        [
          {
            name: "1. 权益法下可转损益的其他综合收益",
            value1: "",
            value2: "",
            code: "1834",
          },
          {
            name: "2. 其他债权投资公允价值变动",
            value1: "",
            value2: "",
            code: "1835",
          },
          {
            name: "3. 金融资产重分类计入其他综合收益的金额",
            value1: "",
            value2: "",
            code: "1836",
          },
          {
            name: "4. 其他债权投资信用减值准备",
            value1: "",
            value2: "",
            code: "1837",
          },
          {
            name: "5. 现金流量套期储备",
            value1: "",
            value2: "",
            code: "1838",
          },
          {
            name: "6. 外币财务报表折算差额",
            value1: "",
            value2: "",
            code: "1839",
          },
        ],
        [
          {
            name: "七、每股收益",
            code: "",
          },
          {
            name: "(一) 基本每股收益",
            value1: "",
            value2: "",
            code: "1291",
          },
          {
            name: "(二) 稀释每股收益",
            value1: "",
            value2: "",
            code: "1292",
          },
        ],
      ],
      // 现金流量表
      tableDataCash: [
        [
          {
            name: "一、经营活动产生的现金流量：",
          },
          {
            name: "销售商品、提供劳务收到的现金",
            value1: "",
            value2: "",
            code: "1100",
          },
          {
            name: "收到的税费返还",
            value1: "",
            value2: "",
            code: "1101",
          },
          {
            name: "收到其他与经营活动有关的现金",
            value1: "",
            value2: "",
            code: "1102",
          },
        ],
        [
          {
            name: "购买商品、接受劳务支付的现金",
            value1: "",
            value2: "",
            code: "1104",
          },
          {
            name: "支付给职工以及为职工支付的现金",
            value1: "",
            value2: "",
            code: "1105",
          },
          {
            name: "支付的各项税费",
            value1: "",
            value2: "",
            code: "1296",
          },
          {
            name: "支付其他与经营活动有关的现金",
            value1: "",
            value2: "",
            code: "1107",
          },
        ],
        [
          {
            name: "二、投资活动产生的现金流量",
          },
          {
            name: "收回投资收到的现金",
            value1: "",
            value2: "",
            code: "1110",
          },
          {
            name: "取得投资收益收到的现金",
            value1: "",
            value2: "",
            code: "1111",
          },
          {
            name: "处置固定资产、无形资产和其他长期资产收回的现金净额（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "112",
          },
          {
            name: "处置子公司及其他经营单位收到的现金净额",
            value1: "",
            value2: "",
            code: "1302",
          },
          {
            name: "收到其他与投资活动有关的现金",
            value1: "",
            value2: "",
            code: "1113",
          },
        ],
        [
          {
            name: "购建固定资产、无形资产和其他长期资产支付的现金",
            value1: "",
            value2: "",
            code: "1115",
          },
          {
            name: "投资支付的现金",
            value1: "",
            value2: "",
            code: "1116",
          },
          {
            name: "取得子公司及其他营业单位支付的现金净额",
            value1: "",
            value2: "",
            code: "1306",
          },
          {
            name: "支付的其他与投资活动有关的现金",
            value1: "",
            value2: "",
            code: "1117",
          },
        ],
        [
          {
            name: "三、筹资活动产生的现金流量",
          },
          {
            name: "吸收投资收到的现金",
            value1: "",
            value2: "",
            code: "1308",
          },
          {
            name: "取得借款收到的现金",
            value1: "",
            value2: "",
            code: "1121",
          },
          {
            name: "收到其他与筹资活动有关的现金",
            value1: "",
            value2: "",
            code: "1122",
          },
        ],
        [
          {
            name: "偿还债务支付的现金",
            value1: "",
            value2: "",
            code: "1124",
          },
          {
            name: "分配股利、利润或偿付利息支付的现金",
            value1: "",
            value2: "",
            code: "1311",
          },
          {
            name: "支付的其他与筹资活动有关的现金",
            value1: "",
            value2: "",
            code: "1126",
          },
        ],
        [
          {
            name: "四、汇率变动对现金及现金等价物的影响",
            value1: "",
            value2: "",
            code: "1129",
          },
        ],
        [
          {
            name: "加：期初现金及现金等价物余额",
            value1: "",
            value2: "",
            code: '1652'
          },
        ],
        [
          {
            name: "1、将净利润调节为经营活动现金流量",
            code: "xjllb38"
          },
          {
            name: "净利润",
            value1: "",
            value2: "",
            code: "xjllb39",
          },
          {
            name: "加：计提的资产减值准备",
            value1: "",
            value2: "",
            code: "xjllb40",
          },
          {
            name: "固定资产折旧、油气资产折耗、生产性生物资产折旧",
            value1: "",
            value2: "",
            code: "xjllb41",
          },
          {
            name: "无形资产摊销",
            value1: "",
            value2: "",
            code: "xjllb42",
          },
          {
            name: "长期待摊费用摊销",
            value1: "",
            value2: "",
            code: "xjllb43",
          },
          {
            name: "处置固定资产、无形资产和其他长期资产的损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb44",
          },
          {
            name: "固定资产报废损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb45",
          },
          {
            name: "公允价值变动损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb46",
          },
          {
            name: "财务费用（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb47",
          },
          {
            name: "投资损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb48",
          },
          {
            name: "递延所得税资产减少（增加以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb49",
          },
          {
            name: "递延所得税负债增加（减少以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb50",
          },
          {
            name: "存货的减少（增加以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb51",
          },
          {
            name: "经营性应收项目的减少（增加以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb52",
          },
          {
            name: "经营性应付项目的增加（减少以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb53",
          },
          {
            name: "其他",
            value1: "",
            value2: "",
            code: "xjllb54",
          },
          // {
          //   name: "经营活动产生的现金流量净额",
          //   value1: "",
          //   value2: "",
          //   code: "xjllb55",
          // },
        ],
        [
          {
            name: "2、不涉及现金收支的投资和筹资活动",
            code: "xjllb56",
          },
          {
            name: "债务转为资本",
            value1: "",
            value2: "",
            code: "xjllb57",
          },
          {
            name: "一年内到期的可转换公司债券",
            value1: "",
            value2: "",
            code: "xjllb58",
          },
          {
            name: "融资租入固定资产",
            value1: "",
            value2: "",
            code: "xjllb59",
          },
        ],
        [
          {
            name: "3、现金及现金等价物净增加情况",
            code: "xjllb60",
          },
          {
            name: "现金的期末余额",
            value1: "",
            value2: "",
            code: "xjllb61",
          },
          {
            name: "减：现金的期初余额",
            value1: "",
            value2: "",
            code: "xjllb62",
          },
          {
            name: "加：现金等价物的期末余额",
            value1: "",
            value2: "",
            code: "xjllb63",
          },
          {
            name: "减：现金等价物的期初余额",
            value1: "",
            value2: "",
            code: "xjllb64",
          },
          // {
          //   name: "现金及现金等价物净增加额",
          //   value1: "",
          //   value2: "",
          //   code: "xjllb65",
          // },
        ],
      ],

      // 合并报表
      // 资产负债表
      balanceSheetMerge: [
        // 资产负债表--流动资产
        [
          {
            name: "流动资产：",
          },
          {
            name: "货币资金",
            value1: "",
            value2: "",
            code: "1001",
          },
          {
            name: "结算备付金",
            value1: "",
            value2: "",
            code: "1464",
          },
          {
            name: "拆出资金",
            value1: "",
            value2: "",
            code: "1409",
          },
          {
            name: "交易性金融资产",
            value1: "",
            value2: "",
            code: "1239",
          },
          {
            name: "衍生金融资产",
            value1: "",
            value2: "",
            code: "1411",
          },
          {
            name: "应收票据",
            value1: "",
            value2: "",
            code: "1003",
          },
          {
            name: "应收账款",
            value1: "",
            value2: "",
            code: "1008",
          },
          {
            name: "应收款项融资",
            value1: "",
            value2: "",
            code: "1657",
          },
          {
            name: "预付款项",
            value1: "",
            value2: "",
            code: "1009",
          },
          {
            name: "应收保费",
            value1: "",
            value2: "",
            code: "1494",
          },
          {
            name: "应收分保账款",
            value1: "",
            value2: "",
            code: "1496",
          },
          {
            name: "应收分保合同准备金",
            value1: "",
            value2: "",
            code: "1578",
          },
          {
            name: "其他应收款",
            value1: "",
            value2: "",
            code: "1210",
          },
          {
            name: "买入返售金融资产",
            value1: "",
            value2: "",
            code: "1412",
          },
          {
            name: "存货",
            value1: "",
            value2: "",
            code: "1014",
          },
          {
            name: "合同资产",
            value1: "",
            value2: "",
            code: "1658",
          },
          {
            name: "持有待售资产",
            value1: "",
            value2: "",
            code: "1821",
          },
          {
            name: "一年内到期的非流动资产",
            value1: "",
            value2: "",
            code: "1241",
          },
          {
            name: "其他流动资产",
            value1: "",
            value2: "",
            code: "1213",
          },
        ],
        // 资产负债表--流动负债
        [
          {
            name: "流动负债：",
          },
          {
            name: "短期借款",
            value1: "",
            value2: "",
            code: "1047",
          },
          {
            name: "向中央银行借款",
            value1: "",
            value2: "",
            code: "1418",
          },
          {
            name: "拆入资金",
            value1: "",
            value2: "",
            code: "1420",
          },
          {
            name: "交易性金融负债",
            value1: "",
            value2: "",
            code: "1255",
          },
          {
            name: "衍生金融负债",
            value1: "",
            value2: "",
            code: "1422",
          },
          {
            name: "应付票据",
            value1: "",
            value2: "",
            code: "1048",
          },
          {
            name: "应付账款",
            value1: "",
            value2: "",
            code: "1049",
          },
          {
            name: "预收款项",
            value1: "",
            value2: "",
            code: "1050",
          },
          {
            name: "合同负债",
            value1: "",
            value2: "",
            code: "1663",
          },
          {
            name: "卖出回购金融资产款",
            value1: "",
            value2: "",
            code: "1423",
          },
          {
            name: "吸收存款及同业存放",
            value1: "",
            value2: "",
            code: "1602",
          },
          {
            name: "代理买卖证券款",
            value1: "",
            value2: "",
            code: "1469",
          },
          {
            name: "代理承销证券款",
            value1: "",
            value2: "",
            code: "1470",
          },
          {
            name: "应付职工薪酬",
            value1: "",
            value2: "",
            code: "1258",
          },
          {
            name: "应交税费",
            value1: "",
            value2: "",
            code: "1259",
          },
          {
            name: "其他应付款",
            value1: "",
            value2: "",
            code: "1056",
          },
          {
            name: "应付手续费及佣金",
            value1: "",
            value2: "",
            code: "1506",
          },
          {
            name: "应付分保账款",
            value1: "",
            value2: "",
            code: "1507",
          },
          {
            name: "持有待售负债",
            value1: "",
            value2: "",
            code: "1696",
          },
          {
            name: "一年内到期的非流动负债",
            value1: "",
            value2: "",
            code: "1262",
          },
          {
            name: "其他流动负债",
            value1: "",
            value2: "",
            code: "1060",
          },
        ],
        // 资产负债表--非流动资产
        [
          {
            name: "非流动资产：",
          },
          {
            name: "发放贷款和垫款",
            value1: "",
            value2: "",
            code: "1413",
          },
          {
            name: "债权投资",
            value1: "",
            value2: "",
            code: "1659",
          },
          {
            name: "其他债权投资",
            value1: "",
            value2: "",
            code: "1660",
          },
          {
            name: "长期应收款",
            value1: "",
            value2: "",
            code: "1244",
          },
          {
            name: "长期股权投资",
            value1: "",
            value2: "",
            code: "1245",
          },
          {
            name: "其他权益工具投资",
            value1: "",
            value2: "",
            code: "1661",
          },
          {
            name: "其他非流动金融资产",
            value1: "",
            value2: "",
            code: "1662",
          },
          {
            name: "投资性房地产",
            value1: "",
            value2: "",
            code: "1246",
          },
          {
            name: "固定资产",
            value1: "",
            value2: "",
            code: "1030",
          },
          {
            name: "在建工程",
            value1: "",
            value2: "",
            code: "1034",
          },
          {
            name: "生产性生物资产",
            value1: "",
            value2: "",
            code: "1247",
          },
          {
            name: "油气资产",
            value1: "",
            value2: "",
            code: "1248",
          },
          {
            name: "使用权资产",
            value1: "",
            value2: "",
            code: "1822",
          },
          {
            name: "无形资产",
            value1: "",
            value2: "",
            code: "1041",
          },
          {
            name: "开发支出",
            value1: "",
            value2: "",
            code: "1249",
          },
          {
            name: "商誉",
            value1: "",
            value2: "",
            code: "1250",
          },
          {
            name: "长期待摊费用",
            value1: "",
            value2: "",
            code: "1251",
          },
          {
            name: "递延所得税资产",
            value1: "",
            value2: "",
            code: "1252",
          },
          {
            name: "其他非流动资产",
            value1: "",
            value2: "",
            code: "1253",
          },
        ],
        // 资产负债表--非流动负债
        [
          {
            name: "非流动负债：",
          },
          {
            name: "保险合同准备金",
            value1: "",
            value2: "",
            code: "1605",
          },
          {
            name: "长期借款",
            value1: "",
            value2: "",
            code: "1062",
          },
          {
            name: "应付债券",
            value1: "",
            value2: "",
            code: "1063",
          },
          {
            name: "其中：优先股",
            value1: "",
            value2: "",
            code: "1919n",
          },
          {
            name: "永续债",
            value1: "",
            value2: "",
            code: "1920n",
          },
          {
            name: "租赁负债",
            value1: "",
            value2: "",
            code: "1697",
          },
          {
            name: "长期应付款",
            value1: "",
            value2: "",
            code: "1064",
          },
          {
            name: "预计负债",
            value1: "",
            value2: "",
            code: "1263",
          },
          {
            name: "递延收益",
            value1: "",
            value2: "",
            code: "1611",
          },
          {
            name: "递延所得税负债",
            value1: "",
            value2: "",
            code: "1264",
          },
          {
            name: "其他非流动负债",
            value1: "",
            value2: "",
            code: "1265",
          },
        ],
        // 资产负债表--所有者权益
        [
          {
            name: "所有者权益(或股东权益)：",
          },
          {
            name: "实收资本(或股本)",
            value1: "",
            value2: "",
            code: "1071",
          },
          {
            name: "其他权益工具",
            value1: "",
            value2: "",
            code: "1268",
          },
          {
            name: "其中：优先股",
            value1: "",
            value2: "",
            code: "1427n",
          },
          {
            name: "永续债",
            value1: "",
            value2: "",
            code: "1428n",
          },
          {
            name: "资本公积",
            value1: "",
            value2: "",
            code: "1072",
          },
          {
            name: "减：库存股",
            value1: "",
            value2: "",
            code: "1269j",
          },
          {
            name: "其他综合收益",
            value1: "",
            value2: "",
            code: "1270",
          },
          {
            name: "专项储备",
            value1: "",
            value2: "",
            code: "1615",
          },
          {
            name: "盈余公积",
            value1: "",
            value2: "",
            code: "1073",
          },
          {
            name: "一般风险准备",
            value1: "",
            value2: "",
            code: "1429",
          },
          {
            name: "未分配利润",
            value1: "",
            value2: "",
            code: "1075",
          },
          {
            name: "归属于母公司所有者权益（或股东权益）合计",
            value1: "0",
            value2: "0",
            code: "1430h",
          },
          {
            name: "少数股东权益",
            value1: "",
            value2: "",
            code: "1070",
          },
        ],
      ],
      // 利润表
      tableDataProfitMerge: [
        [
          {
            name: "其中：营业收入",
            value1: "",
            value2: "",
            code: "1080",
          },
          {
            name: "利息收入",
            value1: "",
            value2: "",
            code: "1873",
          },
          {
            name: "已赚保费",
            value1: "",
            value2: "",
            code: "1517",
          },
          {
            name: "手续费及佣金收入",
            value1: "",
            value2: "",
            code: "1437",
          },
        ],
        [
          {
            name: "其中：营业成本",
            value1: "",
            value2: "",
            code: "1081j",
          },
          {
            name: "利息支出",
            value1: "",
            value2: "",
            code: "1435j",
          },
          {
            name: "手续费及佣金支出",
            value1: "",
            value2: "",
            code: "1438j",
          },
          {
            name: "退保金",
            value1: "",
            value2: "",
            code: "1525j",
          },
          {
            name: "赔付支出净额",
            value1: "",
            value2: "",
            code: "1526j",
          },
          {
            name: "提取保险责任准备金净额",
            value1: "",
            value2: "",
            code: "1627j",
          },
          {
            name: "保单红利支出",
            value1: "",
            value2: "",
            code: "1531j",
          },
          {
            name: "分保费用",
            value1: "",
            value2: "",
            code: "1532j",
          },
          {
            name: "税金及附加",
            value1: "",
            value2: "",
            code: "1082j",
          },
          {
            name: "销售费用",
            value1: "",
            value2: "",
            code: "1086j",
          },
          {
            name: "管理费用",
            value1: "",
            value2: "",
            code: "1087j",
          },
          {
            name: "研发费用",
            value1: "",
            value2: "",
            code: "1666j",
          },
          {
            name: "财务费用",
            value1: "",
            value2: "",
            code: "1088j",
          },
          {
            name: "其中：利息费用",
            value1: "",
            value2: "",
            code: "1088n",
          },
          {
            name: "利息收入",
            value1: "",
            value2: "",
            code: "1684n",
          },
        ],
        [
          {
            name: "加：其他收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1669",
          },
          {
            name: "投资收益",
            value1: "",
            value2: "",
            code: "1281",
          },
          {
            name: "其中：对联营企业和合营企业投资收益",
            value1: "",
            value2: "",
            code: "1282n",
          },
          {
            name: "以摊余成本计量的金融资产终止确认收益",
            value1: "",
            value2: "",
            code: "1670n",
          },
          {
            name: "汇兑收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1441",
          },
          {
            name: "净敞口套期收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1671",
          },
          {
            name: "公允价值变动收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1280",
          },
          {
            name: "信用减值损失（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1654j",
          },
          {
            name: "资产减值损失（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1279j",
          },
          {
            name: "资产处置收益（损失以“-”号填列）",
            value1: "",
            value2: "",
            code: "1633j",
          },
        ],
        [
          {
            name: "加：营业外收入",
            value1: "",
            value2: "",
            code: "1093",
          },
          {
            name: "减：营业外支出",
            value1: "",
            value2: "",
            code: "1632j",
          },
        ],
        [
          {
            name: "减：所得税费用",
            value1: "",
            value2: "",
            code: "1161j",
          },
        ],
        [
          {
            name: "1. 持续经营净利润（净亏损以“-”号填列）",
            value1: "",
            value2: "",
            code: "1825",
          },
          {
            name: "2. 终止经营净利润（净亏损以“-”号填列）",
            value1: "",
            value2: "",
            code: "1826",
          },
        ],
        [
          {
            name: "1. 归属于母公司股东的净利润（净亏损以“-”号填列）",
            value1: "",
            value2: "",
            code: "1450",
          },
          {
            name: "2. 少数股东损益（净亏损以“-”号填列）",
            value1: "",
            value2: "",
            code: "1098",
          },
        ],
        [
          {
            name: "(1)重新计量设定受益计划变动额",
            value1: "",
            value2: "",
            code: "1829",
          },
          {
            name: "(2)权益法下不能转损益的其他综合收益",
            value1: "",
            value2: "",
            code: "1830",
          },
          {
            name: "(3)其他权益工具投资公允价值变动",
            value1: "",
            value2: "",
            code: "1831",
          },
          {
            name: "(4)企业自身信用风险公允价值变动",
            value1: "",
            value2: "",
            code: "1832",
          },
        ],
        [
          {
            name: "(1)权益法下可转损益的其他综合收益",
            value1: "",
            value2: "",
            code: "1834",
          },
          {
            name: "(2)其他债权投资公允价值变动",
            value1: "",
            value2: "",
            code: "1835",
          },
          {
            name: "(3)金融资产重分类计入其他综合收益的金额",
            value1: "",
            value2: "",
            code: "1836",
          },
          {
            name: "(4)其他债权投资信用减值准备",
            value1: "",
            value2: "",
            code: "1837",
          },
          {
            name: "(5)现金流量套期储备",
            value1: "",
            value2: "",
            code: "1838",
          },
          {
            name: "(6)外币财务报表折算差额",
            value1: "",
            value2: "",
            code: "1839",
          },
          {
            name: "(二)归属于少数股东的其他综合收益的税后净额",
            value1: "",
            value2: "",
            code: "1741n",
          },
        ],
        [
          {
            name: "(一) 归属于母公司所有者的综合收益总额",
            value1: "",
            value2: "",
            code: "1640",
          },
          {
            name: "(二) 归属于少数股东的综合收益总额",
            value1: "",
            value2: "",
            code: "1641",
          },
          {
            name: "八、每股收益",
            value1: "",
            value2: "",
            code: "1000",
          },
          {
            name: "(一) 基本每股收益",
            value1: "",
            value2: "",
            code: "1291",
          },
          {
            name: "(二) 稀释每股收益",
            value1: "",
            value2: "",
            code: "1292",
          },
        ],
      ],
      // 现金流量表
      tableDataCashMerge: [
        [
          {
            name: "一、经营活动产生的现金流量",
          },
          {
            name: "销售商品、提供劳务收到的现金",
            value1: "",
            value2: "",
            code: "1100",
          },
          {
            name: "客户存款和同业存放款项净增加额",
            value1: "",
            value2: "",
            code: "1453",
          },
          {
            name: "向中央银行借款净增加额",
            value1: "",
            value2: "",
            code: "1454",
          },
          {
            name: "向其他金融机构拆入资金净增加额",
            value1: "",
            value2: "",
            code: "1455",
          },
          {
            name: "收到原保险合同保费取得的现金",
            value1: "",
            value2: "",
            code: "1536",
          },
          {
            name: "收到再保业务现金净额",
            value1: "",
            value2: "",
            code: "1537",
          },
          {
            name: "保户储金及投资款净增加额",
            value1: "",
            value2: "",
            code: "1538",
          },
          {
            name: "收取利息、手续费及佣金的现金",
            value1: "",
            value2: "",
            code: "1456",
          },
          {
            name: "拆入资金净增加额",
            value1: "",
            value2: "",
            code: "1483",
          },
          {
            name: "回购业务资金净增加额",
            value1: "",
            value2: "",
            code: "1484",
          },
          {
            name: "代理买卖证券收到的现金净额",
            value1: "",
            value2: "",
            code: "1818",
          },
          {
            name: "收到的税费返还",
            value1: "",
            value2: "",
            code: "1101",
          },
          {
            name: "收到其他与经营活动有关的现金",
            value1: "",
            value2: "",
            code: "1102",
          },
        ],
        [
          {
            name: "购买商品、接受劳务支付的现金",
            value1: "",
            value2: "",
            code: "1104",
          },
          {
            name: "客户贷款及垫款净增加额",
            value1: "",
            value2: "",
            code: "1457",
          },
          {
            name: "存放中央银行和同业款项净增加额",
            value1: "",
            value2: "",
            code: "1458",
          },
          {
            name: "支付原保险合同赔付款项的现金",
            value1: "",
            value2: "",
            code: "1645",
          },
          {
            name: "拆出资金净增加额",
            value1: "",
            value2: "",
            code: "1680",
          },
          {
            name: "支付利息、手续费及佣金的现金",
            value1: "",
            value2: "",
            code: "1459",
          },
          {
            name: "支付保单红利的现金",
            value1: "",
            value2: "",
            code: "1541",
          },
          {
            name: "支付给职工及为职工支付的现金",
            value1: "",
            value2: "",
            code: "1105",
          },
          {
            name: "支付的各项税费",
            value1: "",
            value2: "",
            code: "1296",
          },
          {
            name: "支付其他与经营活动有关的现金",
            value1: "",
            value2: "",
            code: "1107",
          },
        ],
        [
          {
            name: "二、投资活动产生的现金流量",
          },
          {
            name: "收回投资收到的现金",
            value1: "",
            value2: "",
            code: "1110",
          },
          {
            name: "取得投资收益收到的现金",
            value1: "",
            value2: "",
            code: "1111",
          },
          {
            name: "处置固定资产、无形资产和其他长期资产收回的现金净额",
            value1: "",
            value2: "",
            code: "1112",
          },
          {
            name: "处置子公司及其他经营单位收到的现金净额",
            value1: "",
            value2: "",
            code: "1302",
          },
          {
            name: "收到其他与投资活动有关的现金",
            value1: "",
            value2: "",
            code: "1113",
          },
        ],
        [
          {
            name: "购建固定资产、无形资产和其他长期资产支付的现金",
            value1: "",
            value2: "",
            code: "1115",
          },
          {
            name: "投资支付的现金",
            value1: "",
            value2: "",
            code: "1116",
          },
          {
            name: "质押贷款净增加额",
            value1: "",
            value2: "",
            code: "1646",
          },
          {
            name: "取得子公司及其他营业单位支付的现金净额",
            value1: "",
            value2: "",
            code: "1306",
          },
          {
            name: "支付的其他与投资活动有关的现金",
            value1: "",
            value2: "",
            code: "1117",
          },
        ],
        [
          {
            name: "三、筹资活动产生的现金流量",
          },
          {
            name: "吸收投资收到的现金",
            value1: "",
            value2: "",
            code: "1308",
          },
          {
            name: "其中：子公司吸收少数股东投资收到的现金",
            value1: "",
            value2: "",
            code: "1648n",
          },
          {
            name: "取得借款收到的现金",
            value1: "",
            value2: "",
            code: "1121",
          },
          {
            name: "收到其他与筹资活动有关的现金",
            value1: "",
            value2: "",
            code: "1122",
          },
        ],
        [
          {
            name: "偿还债务支付的现金",
            value1: "",
            value2: "",
            code: "1124",
          },
          {
            name: "分配股利、利润或偿付利息支付的现金",
            value1: "",
            value2: "",
            code: "1311",
          },
          {
            name: "其中：子公司支付给少数股东的股利、利润",
            value1: "",
            value2: "",
            code: "1649n",
          },
          {
            name: "支付的其他与筹资活动有关的现金",
            value1: "",
            value2: "",
            code: "1126",
          },
        ],
        [
          {
            name: "四、汇率变动对现金及现金等价物的影响",
            value1: "",
            value2: "",
            code: "1129",
          },
        ],
        [
          {
            name: "加：期初现金及现金等价物余额",
            value1: "",
            value2: "",
            code: "1652",
          },
        ],
        [
          {
            name: "1、将净利润调节为经营活动现金流量",
            code: "xjllb38"
          },
          {
            name: "净利润",
            value1: "",
            value2: "",
            code: "xjllb39",
          },
          {
            name: "加：计提的资产减值准备",
            value1: "",
            value2: "",
            code: "xjllb40",
          },
          {
            name: "固定资产折旧、油气资产折耗、生产性生物资产折旧",
            value1: "",
            value2: "",
            code: "xjllb41",
          },
          {
            name: "无形资产摊销",
            value1: "",
            value2: "",
            code: "xjllb42",
          },
          {
            name: "长期待摊费用摊销",
            value1: "",
            value2: "",
            code: "xjllb43",
          },
          {
            name: "处置固定资产、无形资产和其他长期资产的损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb44",
          },
          {
            name: "固定资产报废损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb45",
          },
          {
            name: "公允价值变动损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb46",
          },
          {
            name: "财务费用（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb47",
          },
          {
            name: "投资损失（收益以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb48",
          },
          {
            name: "递延所得税资产减少（增加以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb49",
          },
          {
            name: "递延所得税负债增加（减少以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb50",
          },
          {
            name: "存货的减少（增加以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb51",
          },
          {
            name: "经营性应收项目的减少（增加以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb52",
          },
          {
            name: "经营性应付项目的增加（减少以“-”号填列）",
            value1: "",
            value2: "",
            code: "xjllb53",
          },
          {
            name: "其他",
            value1: "",
            value2: "",
            code: "xjllb54",
          },
          // {
          //   name: "经营活动产生的现金流量净额",
          //   value1: "",
          //   value2: "",
          //   code: "xjllb55",
          // },
        ],
        [
          {
            name: "2、不涉及现金收支的投资和筹资活动",
            code: "xjllb56",
          },
          {
            name: "债务转为资本",
            value1: "",
            value2: "",
            code: "xjllb57",
          },
          {
            name: "一年内到期的可转换公司债券",
            value1: "",
            value2: "",
            code: "xjllb58",
          },
          {
            name: "融资租入固定资产",
            value1: "",
            value2: "",
            code: "xjllb59",
          },
        ],
        [
          {
            name: "3、现金及现金等价物净增加情况",
            code: "xjllb60",
          },
          {
            name: "现金的期末余额",
            value1: "",
            value2: "",
            code: "xjllb61",
          },
          {
            name: "减：现金的期初余额",
            value1: "",
            value2: "",
            code: "xjllb62",
          },
          {
            name: "加：现金等价物的期末余额",
            value1: "",
            value2: "",
            code: "xjllb63",
          },
          {
            name: "减：现金等价物的期初余额",
            value1: "",
            value2: "",
            code: "xjllb64",
          },
          // {
          //   name: "现金及现金等价物净增加额",
          //   value1: "",
          //   value2: "",
          //   code: "xjllb65",
          // },
        ],
      ],

      // 资产负债表合计
      TotalCurrentAssets: [], //流动资产合计数组
      TotalCurrentLiability: [], //流动负债合计数组
      FlagTotalCurrentAssets: [], //非流动资产合计数组
      FlagTotalCurrentLiability: [], //非流动负债合计数组
      ownershipInterest: [], //所有者权益
      // 单体利润表合计
      operatingProfit: [], //营业利润
      TotalProfit: [], //利润总额
      retained: [], //净利润
      statement: [], //(一)损益其他综合收益
      reclassification: [], //重分类损益

      // 单体现金流量表
      getActivitiesTotal: [], //经营活动现金流入小计
      getActivitiesTotalOut: [], //经营活动现金流出小计
      investmentTotals: [], //投资活动现金流入小计
      investmentOut: [], //投资活动现金流出小计
      getSummariesTotal: [], //筹资活动流入小计
      getSummariesOut: [], //筹资活动流出小计
      getTexchange: [], //筹资活动现金流量净额
      getTexchanges: [],
      supplementToken: [], //补充
      supplementsToken: [],

      // 合并报表-资产负债
      TotalCurrentAssetss: [], //流动资产合计数组
      TotalCurrentLiabilitys: [], //流动负债合计数组
      FlagTotalCurrentAssetss: [], //非流动资产合计数组
      FlagTotalCurrentLiabilitys: [], //非流动负债合计数组
      ownershipInterests: [], //所有者权益

      // 合并利润表合计
      operatingProfits: [], //一、营业总收入
      TotalProfits: [], //营业总成本
      retaineds: [], //营业利润
      statements: [], //利润总额
      statementsact: [], //五、净利润
      SummarieClass: [], //经营持续分类
      SummarieClasss: [], //经营持续分类
      out: 0, //二）归属于少数股东的其他综合收益的税后净额
      noClass: [], //1．不能重分类进损益的其他综合收益
      noClasss: [], //2．将重分类进损益的其他综合收益

      // 合并现金流量表
      getActivitiesTotals: [], //经营活动现金流入小计
      getActivitiesTotalOuts: [], //经营活动现金流出小计
      investmentTotalss: [], //投资活动现金流入小计
      investmentOuts: [], //投资活动现金流出小计
      getSummariesTotals: [], //筹资活动流入小计
      getSummariesOuts: [], //筹资活动流出小计

      getTexchanges: [], //
      getTexchangess: [],

      // supplementToken: [], //补充
      // supplementsToken: [],

      dialogVisible: false,
      codetype: this.$route.query.report_type,
      dateTime: "",
      activeName: "0",
      BType: "",
      editTime: "",
      editTimeData: [],
      tableDataCashType: true,
      tableDataProfitType: true,
      tableDataSheetType: true,
      disabledType: this.$route.query.type,
      company_code: sesstion.getLocalData("company").company_code,
      rowData: sesstion.getLocalData("rowData"),
      options: [
        {
          value: "00",
          label: "合并报表",
        },
        {
          value: "01",
          label: "本部报表",
        },
      ],

      pageTypeList: [
        {
          key: "00",
          value: "年报",
        },
        {
          key: "01",
          value: "月报",
        },
        // {
        //   key: "01",
        //   value: "三季报",
        // },
        // {
        //   key: "02",
        //   value: "半年报",
        // },
        // {
        //   key: "03",
        //   value: "一季报",
        // },
        // {
        //   key: "001",
        //   value: "一月报",
        // },
        // {
        //   key: "002",
        //   value: "二月报",
        // },
        // {
        //   key: "003",
        //   value: "三月报",
        // },
        // {
        //   key: "004",
        //   value: "四月报",
        // },
        // {
        //   key: "005",
        //   value: "五月报",
        // },
        // {
        //   key: "006",
        //   value: "六月报",
        // },
        // {
        //   key: "007",
        //   value: "七月报",
        // },
        // {
        //   key: "008",
        //   value: "八月报",
        // },
        // {
        //   key: "009",
        //   value: "九月报",
        // },
        // {
        //   key: "010",
        //   value: "十月报",
        // },
        // {
        //   key: "011",
        //   value: "十一月报",
        // },
        // {
        //   key: "012",
        //   value: "十二月报",
        // },
      ],

      dd: "",
      sheet: false,
    };
  },

  created() {
    this.getType()
    if (this.$route.query.time == '00') {
      this.dateTime = this.$route.query.year
    } else {
      this.dateTime = this.$route.query.year + '-' + this.$route.query.time.substring(1, 3)
    }

  },
  watch: {},
  mounted() {
    this.getDataList();
    this.$nextTick(() => {
      this.initInputDOM()
    })
  },
  methods: {
    getType() {
      if (this.$route.query.time == '00') {
        this.BType = this.$route.query.time
      } else {
        this.BType = '01'
      }
    },
    editTimeChange(value) {
      this.editTime = value
      let data = sesstion.getLocalData("TimeList")
      let param = {
        log_id: this.editTime,
        company_code: data.company_code,
        report_year: data.report_year,
        report_time: data.report_time,
        report_type: data.report_type,
      };
      change_logFs(param).then((res) => {
        if (res.data.change_log[0].formated.report_time == '00') {
          this.BType = res.data.change_log[0].formated.report_time;
          this.codetype = res.data.change_log[0].formated.report_type;
          this.dateTime = res.data.change_log[0].formated.report_year
        } else {
          this.BType = '01'
          this.codetype = res.data.change_log[0].formated.report_type;
          this.dateTime = res.data.change_log[0].formated.report_year + '-' + res.data.change_log[0].formated.report_time.substring(1, 3)
        }

        if (res.data.change_log[0].formated.report_type == "00") {

          this.balanceSheetMerge = res.data.change_log[0].formated.tableDataSheet;

          delete this.balanceSheetMerge[0][this.balanceSheetMerge[0].length - 1];
          delete this.balanceSheetMerge[1][this.balanceSheetMerge[1].length - 1];
          delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 1];
          delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 2];
          delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 1];
          delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 2];
          delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 1];
          delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 2];

          this.tableDataProfitMerge = res.data.change_log[0].formated.tableDataProfit;

          delete this.tableDataProfitMerge[0][this.tableDataProfitMerge[0].length - 1];
          delete this.tableDataProfitMerge[1][this.tableDataProfitMerge[1].length - 1];
          delete this.tableDataProfitMerge[2][this.tableDataProfitMerge[2].length - 1];
          delete this.tableDataProfitMerge[3][this.tableDataProfitMerge[3].length - 1];
          delete this.tableDataProfitMerge[4][this.tableDataProfitMerge[4].length - 1];
          delete this.tableDataProfitMerge[5][this.tableDataProfitMerge[5].length - 1];
          delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 1];
          delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 2];
          delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 3];
          delete this.tableDataProfitMerge[7][this.tableDataProfitMerge[7].length - 1];
          delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 1];
          delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 2];

          this.tableDataCashMerge = res.data.change_log[0].formated.tableDataCash;
          delete this.tableDataCashMerge[0][this.tableDataCashMerge[0].length - 1];
          delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 1];
          delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 2];
          delete this.tableDataCashMerge[2][this.tableDataCashMerge[2].length - 1];
          delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 1];
          delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 2];
          delete this.tableDataCashMerge[4][this.tableDataCashMerge[4].length - 1];
          delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 1];
          delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 2];
          delete this.tableDataCashMerge[6][this.tableDataCashMerge[6].length - 1];
          delete this.tableDataCashMerge[7][this.tableDataCashMerge[7].length - 1];

          setTimeout(() => {
            this.qyhj()//所有者权益
            this.SummarBlur()//利润表
            this.xjl_bl()//现金流
          }, 2000)
        } else {
          this.balanceSheet = res.data.change_log[0].formated.tableDataSheet;
          delete this.balanceSheet[0][this.balanceSheet[0].length - 1];
          delete this.balanceSheet[1][this.balanceSheet[1].length - 1];
          delete this.balanceSheet[2][this.balanceSheet[2].length - 1];
          delete this.balanceSheet[2][this.balanceSheet[2].length - 2];
          delete this.balanceSheet[3][this.balanceSheet[3].length - 1];
          delete this.balanceSheet[3][this.balanceSheet[3].length - 2];
          delete this.balanceSheet[4][this.balanceSheet[4].length - 1];
          delete this.balanceSheet[4][this.balanceSheet[4].length - 2];
          this.tableDataProfit = res.data.change_log[0].formated.tableDataProfit;
          delete this.tableDataProfit[0][this.tableDataProfit[0].length - 1];
          delete this.tableDataProfit[1][this.tableDataProfit[1].length - 1];
          delete this.tableDataProfit[2][this.tableDataProfit[2].length - 1];
          delete this.tableDataProfit[3][this.tableDataProfit[3].length - 1];
          delete this.tableDataProfit[4][this.tableDataProfit[4].length - 1];
          delete this.tableDataProfit[5][this.tableDataProfit[5].length - 1];

          this.tableDataCash = res.data.change_log[0].formated.tableDataCash;
          delete this.tableDataCash[0][this.tableDataCash[0].length - 1];
          delete this.tableDataCash[1][this.tableDataCash[1].length - 1];
          delete this.tableDataCash[1][this.tableDataCash[1].length - 2];
          delete this.tableDataCash[2][this.tableDataCash[2].length - 1];
          delete this.tableDataCash[3][this.tableDataCash[3].length - 1];
          delete this.tableDataCash[3][this.tableDataCash[3].length - 2];
          delete this.tableDataCash[4][this.tableDataCash[4].length - 1];
          delete this.tableDataCash[5][this.tableDataCash[5].length - 1];
          delete this.tableDataCash[5][this.tableDataCash[5].length - 2];
          delete this.tableDataCash[6][this.tableDataCash[6].length - 1];
          delete this.tableDataCash[7][1];
          delete this.tableDataCash[8][this.tableDataCash[8].length - 1];
          delete this.tableDataCash[10][this.tableDataCash[10].length - 1];

          setTimeout(() => {
            this.blur_lr()//利润表
            this.xjl_zje()//现金流
          }, 2000)
        }
      })
    },
    // 修改和查看
    getDataList() {
      // 0手工录入
      if (this.$route.query.type == "0") {
        // 获取临时存储
        var param = {}
        if (this.BType == '01') {
          let report_time = '0' + this.dateTime.substring(5, 7)
          param = {
            "company_code": this.company_code,
            "report_time": report_time,
            "report_type": this.codetype,
            "report_year": this.dateTime.substring(0, 4)
          }
        } else {
          param = {
            "company_code": this.company_code,
            "report_time": this.BType,
            "report_type": this.codetype,
            "report_year": this.dateTime
          }
        }
        hand_save_read(param).then((res) => {
          
          if (res.data.Status == 0) {
            this.dataObj = sesstion.getLocalData("Data")
            this.tableDataCashType = this.dataObj.Data.judge_state.tableDataCash
            this.tableDataProfitType = this.dataObj.Data.judge_state.tableDataProfit
            this.tableDataSheetType = this.dataObj.Data.judge_state.tableDataSheet
            if (this.dataObj.Status == '0') {
              this.$message({
                type: 'warning',
                message: this.dataObj.Message
              });
              if (this.dataObj.Data.report_type == "00") {
                this.balanceSheetMerge = this.dataObj.Data.tableDataSheet;
                delete this.balanceSheetMerge[0][this.balanceSheetMerge[0].length - 1];
                delete this.balanceSheetMerge[1][this.balanceSheetMerge[1].length - 1];
                delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 1];
                delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 2];
                delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 1];
                delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 2];
                delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 1];
                delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 2];
                

                this.tableDataProfitMerge = this.dataObj.Data.tableDataProfit;
                delete this.tableDataProfitMerge[0][this.tableDataProfitMerge[0].length - 1];
                delete this.tableDataProfitMerge[1][this.tableDataProfitMerge[1].length - 1];
                delete this.tableDataProfitMerge[2][this.tableDataProfitMerge[2].length - 1];
                delete this.tableDataProfitMerge[3][this.tableDataProfitMerge[3].length - 1];
                delete this.tableDataProfitMerge[4][this.tableDataProfitMerge[4].length - 1];
                delete this.tableDataProfitMerge[5][this.tableDataProfitMerge[5].length - 1];
                delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 1];
                delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 2];
                delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 3];
                delete this.tableDataProfitMerge[7][this.tableDataProfitMerge[7].length - 1];
                delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 1];
                delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 2];

                this.tableDataCashMerge = this.dataObj.Data.tableDataCash;
                delete this.tableDataCashMerge[0][this.tableDataCashMerge[0].length - 1];
                delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 1];
                delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 2];
                delete this.tableDataCashMerge[2][this.tableDataCashMerge[2].length - 1];
                delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 1];
                delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 2];
                delete this.tableDataCashMerge[4][this.tableDataCashMerge[4].length - 1];
                delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 1];
                delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 2];
                delete this.tableDataCashMerge[6][this.tableDataCashMerge[6].length - 1];
                delete this.tableDataCashMerge[7][this.tableDataCashMerge[7].length - 1];

                setTimeout(() => {
                  this.qyhj()//所有者权益
                  this.SummarBlur()//利润表
                  this.xjl_bl()//现金流
                }, 2000)
              } else {
                this.balanceSheet = this.dataObj.Data.tableDataSheet;
                delete this.balanceSheet[0][this.balanceSheet[0].length - 1];
                delete this.balanceSheet[1][this.balanceSheet[1].length - 1];
                delete this.balanceSheet[2][this.balanceSheet[2].length - 1];
                delete this.balanceSheet[2][this.balanceSheet[2].length - 2];
                delete this.balanceSheet[3][this.balanceSheet[3].length - 1];
                delete this.balanceSheet[3][this.balanceSheet[3].length - 2];
                delete this.balanceSheet[4][this.balanceSheet[4].length - 1];
                delete this.balanceSheet[4][this.balanceSheet[4].length - 2];

                this.tableDataProfit = this.dataObj.Data.tableDataProfit;

                delete this.tableDataProfit[0][this.tableDataProfit[0].length - 1];
                delete this.tableDataProfit[1][this.tableDataProfit[1].length - 1];
                delete this.tableDataProfit[2][this.tableDataProfit[2].length - 1];
                delete this.tableDataProfit[3][this.tableDataProfit[3].length - 1];
                delete this.tableDataProfit[4][this.tableDataProfit[4].length - 1];
                delete this.tableDataProfit[5][this.tableDataProfit[5].length - 1];

                this.tableDataCash = this.dataObj.Data.tableDataCash;
                delete this.tableDataCash[0][this.tableDataCash[0].length - 1];
                delete this.tableDataCash[1][this.tableDataCash[1].length - 1];
                delete this.tableDataCash[1][this.tableDataCash[1].length - 2];
                delete this.tableDataCash[2][this.tableDataCash[2].length - 1];
                delete this.tableDataCash[3][this.tableDataCash[3].length - 1];
                delete this.tableDataCash[3][this.tableDataCash[3].length - 2];
                delete this.tableDataCash[4][this.tableDataCash[4].length - 1];
                delete this.tableDataCash[5][this.tableDataCash[5].length - 1];
                delete this.tableDataCash[5][this.tableDataCash[5].length - 2];
                delete this.tableDataCash[6][this.tableDataCash[6].length - 1];
                delete this.tableDataCash[7][1];
                delete this.tableDataCash[8][this.tableDataCash[8].length - 1];
                delete this.tableDataCash[10][this.tableDataCash[10].length - 1];

                setTimeout(() => {
                  this.blur_lr()//利润表
                  this.xjl_zje()//现金流
                }, 2000)
              }
            } else {
              this.$message({
                type: 'success',
                message: this.dataObj.Message
              });
              if (this.dataObj.Data.report_type == "00") {
                this.balanceSheetMerge = this.dataObj.Data.tableDataSheet;
                delete this.balanceSheetMerge[0][this.balanceSheetMerge[0].length - 1];
                delete this.balanceSheetMerge[1][this.balanceSheetMerge[1].length - 1];
                delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 1];
                delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 2];
                delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 1];
                delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 2];
                delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 1];
                delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 2];
                

                this.tableDataProfitMerge = this.dataObj.Data.tableDataProfit;
                delete this.tableDataProfitMerge[0][this.tableDataProfitMerge[0].length - 1];
                delete this.tableDataProfitMerge[1][this.tableDataProfitMerge[1].length - 1];
                delete this.tableDataProfitMerge[2][this.tableDataProfitMerge[2].length - 1];
                delete this.tableDataProfitMerge[3][this.tableDataProfitMerge[3].length - 1];
                delete this.tableDataProfitMerge[4][this.tableDataProfitMerge[4].length - 1];
                delete this.tableDataProfitMerge[5][this.tableDataProfitMerge[5].length - 1];
                delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 1];
                delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 2];
                delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 3];
                delete this.tableDataProfitMerge[7][this.tableDataProfitMerge[7].length - 1];
                delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 1];
                delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 2];

                this.tableDataCashMerge = this.dataObj.Data.tableDataCash;
                delete this.tableDataCashMerge[0][this.tableDataCashMerge[0].length - 1];
                delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 1];
                delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 2];
                delete this.tableDataCashMerge[2][this.tableDataCashMerge[2].length - 1];
                delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 1];
                delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 2];
                delete this.tableDataCashMerge[4][this.tableDataCashMerge[4].length - 1];
                delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 1];
                delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 2];
                delete this.tableDataCashMerge[6][this.tableDataCashMerge[6].length - 1];
                delete this.tableDataCashMerge[7][this.tableDataCashMerge[7].length - 1];

                setTimeout(() => {
                  this.qyhj()//所有者权益
                  this.SummarBlur()//利润表
                  this.xjl_bl()//现金流
                }, 2000)
              } else {
                this.balanceSheet = this.dataObj.Data.tableDataSheet;
                delete this.balanceSheet[0][this.balanceSheet[0].length - 1];
                delete this.balanceSheet[1][this.balanceSheet[1].length - 1];
                delete this.balanceSheet[2][this.balanceSheet[2].length - 1];
                delete this.balanceSheet[2][this.balanceSheet[2].length - 2];
                delete this.balanceSheet[3][this.balanceSheet[3].length - 1];
                delete this.balanceSheet[3][this.balanceSheet[3].length - 2];
                delete this.balanceSheet[4][this.balanceSheet[4].length - 1];
                delete this.balanceSheet[4][this.balanceSheet[4].length - 2];

                this.tableDataProfit = this.dataObj.Data.tableDataProfit;

                delete this.tableDataProfit[0][this.tableDataProfit[0].length - 1];
                delete this.tableDataProfit[1][this.tableDataProfit[1].length - 1];
                delete this.tableDataProfit[2][this.tableDataProfit[2].length - 1];
                delete this.tableDataProfit[3][this.tableDataProfit[3].length - 1];
                delete this.tableDataProfit[4][this.tableDataProfit[4].length - 1];
                delete this.tableDataProfit[5][this.tableDataProfit[5].length - 1];

                this.tableDataCash = this.dataObj.Data.tableDataCash;
                delete this.tableDataCash[0][this.tableDataCash[0].length - 1];
                delete this.tableDataCash[1][this.tableDataCash[1].length - 1];
                delete this.tableDataCash[1][this.tableDataCash[1].length - 2];
                delete this.tableDataCash[2][this.tableDataCash[2].length - 1];
                delete this.tableDataCash[3][this.tableDataCash[3].length - 1];
                delete this.tableDataCash[3][this.tableDataCash[3].length - 2];
                delete this.tableDataCash[4][this.tableDataCash[4].length - 1];
                delete this.tableDataCash[5][this.tableDataCash[5].length - 1];
                delete this.tableDataCash[5][this.tableDataCash[5].length - 2];
                delete this.tableDataCash[6][this.tableDataCash[6].length - 1];
                delete this.tableDataCash[7][1];
                delete this.tableDataCash[8][this.tableDataCash[8].length - 1];
                delete this.tableDataCash[10][this.tableDataCash[10].length - 1];

                setTimeout(() => {
                  this.blur_lr()//利润表
                  this.xjl_zje()//现金流
                }, 2000)
              }
            }
          } else {
            this.$confirm(`系统中有尚未完成的已保存报表，是否读取数据？ 注：点击 "取消" 将重新填写`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',

            }).then(() => {
              this.tableDataCashType = false
              this.tableDataProfitType = false
              this.tableDataSheetType = false
              if (res.data.Message.report_time == '00') {
                this.BType = res.data.Message.report_time;
                this.codetype = res.data.Message.report_type;
                this.dateTime = res.data.Message.report_year
              } else {
                this.BType = '01'
                this.codetype = res.data.Message.report_type;
                this.dateTime = res.data.Message.report_year + "-" + res.data.Message.report_time.substring(1, 3)
                // console.log(this.BType,this.codetype,this.dateTime);
              }
              if (res.data.Message.report_type == "00") {
                let one = res.data.Message.tableDataSheet;
                one[0].pop();
                one[1].pop();
                one[2].pop();
                one[2].pop();
                one[3].pop();
                one[3].pop();
                one[4].pop();
                one[4].pop();
                let oneArr = []
                one.forEach((item, index) => {
                  var oneData = []
                  item.map((ele, i) => {
                    if (ele !== null) {
                      oneData.push(ele)
                    }
                  })
                  oneArr.push(oneData)
                })
                this.balanceSheetMerge = oneArr


                let two = res.data.Message.tableDataProfit;
                two[0].pop();
                two[1].pop();
                two[2].pop();
                two[3].pop();
                two[4].pop();
                two[5].pop();
                two[6].pop();
                two[6].pop();
                two[6].pop();
                two[7].pop();
                two[8].pop();
                two[8].pop();
                let twoArr = []
                two.forEach((item, index) => {
                  var twoData = []
                  item.map((ele, i) => {
                    if (ele !== null) {
                      twoData.push(ele)
                    }
                  })
                  twoArr.push(twoData)
                })
                this.tableDataProfitMerge = twoArr

                let thr = res.data.Message.tableDataCash;
                thr[0].pop();
                thr[1].pop();
                thr[1].pop();
                thr[2].pop();
                thr[3].pop();
                thr[3].pop();
                thr[4].pop();
                thr[5].pop();
                thr[5].pop();
                thr[6].pop();
                thr[7].pop();
                let thrArr = []
                thr.forEach((item, index) => {
                  var thrData = []
                  item.map((ele, i) => {
                    if (ele !== null) {
                      thrData.push(ele)
                    }
                  })
                  thrArr.push(thrData)
                })
                this.tableDataCashMerge = thrArr
                // console.log(this.tableDataCashMerge);

                setTimeout(() => {
                  this.qyhj()//所有者权益
                  this.SummarBlur()//利润表
                  this.xjl_bl()//现金流
                }, 2000)

              } else {
                let one = res.data.Message.tableDataSheet;
                one[0].pop();
                one[1].pop();
                one[2].pop();
                one[2].pop();
                one[3].pop();
                one[3].pop();
                one[4].pop();
                one[4].pop();
                let oneArr = []
                one.forEach((item, index) => {
                  var oneData = []
                  item.map((ele, i) => {
                    if (ele !== null) {
                      oneData.push(ele)
                    }
                  })
                  oneArr.push(oneData)
                })
                this.balanceSheet = oneArr
                // console.log(this.balanceSheet);

                let two = res.data.Message.tableDataProfit;

                two[0].pop();
                two[1].pop();
                two[2].pop();
                two[3].pop();
                two[4].pop();
                two[5].pop();
                let twoArr = []
                two.forEach((item, index) => {
                  var twoData = []
                  item.map((ele, i) => {
                    if (ele !== null) {
                      twoData.push(ele)
                    }
                  })
                  twoArr.push(twoData)
                })
                this.tableDataProfit = twoArr
                // console.log( this.tableDataProfit);

                let thr = res.data.Message.tableDataCash;
                thr[0].pop()
                thr[1].pop()
                thr[1].pop()
                thr[2].pop()
                thr[3].pop()
                thr[3].pop()
                thr[4].pop()
                thr[5].pop()
                thr[5].pop()
                thr[6].pop()
                thr[7].pop()
                thr[8].pop()
                thr[10].pop()
                let thrArr = []
                thr.forEach((item, index) => {
                  var thrData = []
                  item.map((ele, i) => {
                    if (ele !== null) {
                      thrData.push(ele)
                    }
                  })
                  thrArr.push(thrData)
                })
                this.tableDataCash = thrArr
                // console.log(this.tableDataCash);
                setTimeout(() => {
                  this.blur_lr()//利润表
                  this.xjl_zje()//现金流
                }, 2000)
              }
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });
              this.dataObj = sesstion.getLocalData("Data")
              this.tableDataCashType = this.dataObj.Data.judge_state.tableDataCash
              this.tableDataProfitType = this.dataObj.Data.judge_state.tableDataProfit
              this.tableDataSheetType = this.dataObj.Data.judge_state.tableDataSheet
              if (this.dataObj.Status == '0') {
                this.$message({
                  type: 'warning',
                  message: this.dataObj.Message
                });
                if (this.dataObj.Data.report_type == "00") {
                  this.balanceSheetMerge = this.dataObj.Data.tableDataSheet;
                  delete this.balanceSheetMerge[0][this.balanceSheetMerge[0].length - 1];
                  delete this.balanceSheetMerge[1][this.balanceSheetMerge[1].length - 1];
                  delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 1];
                  delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 2];
                  delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 1];
                  delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 2];
                  delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 1];
                  delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 2];
                  

                  this.tableDataProfitMerge = this.dataObj.Data.tableDataProfit;
                  delete this.tableDataProfitMerge[0][this.tableDataProfitMerge[0].length - 1];
                  delete this.tableDataProfitMerge[1][this.tableDataProfitMerge[1].length - 1];
                  delete this.tableDataProfitMerge[2][this.tableDataProfitMerge[2].length - 1];
                  delete this.tableDataProfitMerge[3][this.tableDataProfitMerge[3].length - 1];
                  delete this.tableDataProfitMerge[4][this.tableDataProfitMerge[4].length - 1];
                  delete this.tableDataProfitMerge[5][this.tableDataProfitMerge[5].length - 1];
                  delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 1];
                  delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 2];
                  delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 3];
                  delete this.tableDataProfitMerge[7][this.tableDataProfitMerge[7].length - 1];
                  delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 1];
                  delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 2];

                  this.tableDataCashMerge = this.dataObj.Data.tableDataCash;
                  delete this.tableDataCashMerge[0][this.tableDataCashMerge[0].length - 1];
                  delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 1];
                  delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 2];
                  delete this.tableDataCashMerge[2][this.tableDataCashMerge[2].length - 1];
                  delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 1];
                  delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 2];
                  delete this.tableDataCashMerge[4][this.tableDataCashMerge[4].length - 1];
                  delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 1];
                  delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 2];
                  delete this.tableDataCashMerge[6][this.tableDataCashMerge[6].length - 1];
                  delete this.tableDataCashMerge[7][this.tableDataCashMerge[7].length - 1];

                  setTimeout(() => {
                    this.qyhj()//所有者权益
                    this.SummarBlur()//利润表
                    this.xjl_bl()//现金流
                  }, 2000)
                } else {
                  this.balanceSheet = this.dataObj.Data.tableDataSheet;
                  delete this.balanceSheet[0][this.balanceSheet[0].length - 1];
                  delete this.balanceSheet[1][this.balanceSheet[1].length - 1];
                  delete this.balanceSheet[2][this.balanceSheet[2].length - 1];
                  delete this.balanceSheet[2][this.balanceSheet[2].length - 2];
                  delete this.balanceSheet[3][this.balanceSheet[3].length - 1];
                  delete this.balanceSheet[3][this.balanceSheet[3].length - 2];
                  delete this.balanceSheet[4][this.balanceSheet[4].length - 1];
                  delete this.balanceSheet[4][this.balanceSheet[4].length - 2];

                  this.tableDataProfit = this.dataObj.Data.tableDataProfit;

                  delete this.tableDataProfit[0][this.tableDataProfit[0].length - 1];
                  delete this.tableDataProfit[1][this.tableDataProfit[1].length - 1];
                  delete this.tableDataProfit[2][this.tableDataProfit[2].length - 1];
                  delete this.tableDataProfit[3][this.tableDataProfit[3].length - 1];
                  delete this.tableDataProfit[4][this.tableDataProfit[4].length - 1];
                  delete this.tableDataProfit[5][this.tableDataProfit[5].length - 1];

                  this.tableDataCash = this.dataObj.Data.tableDataCash;
                  delete this.tableDataCash[0][this.tableDataCash[0].length - 1];
                  delete this.tableDataCash[1][this.tableDataCash[1].length - 1];
                  delete this.tableDataCash[1][this.tableDataCash[1].length - 2];
                  delete this.tableDataCash[2][this.tableDataCash[2].length - 1];
                  delete this.tableDataCash[3][this.tableDataCash[3].length - 1];
                  delete this.tableDataCash[3][this.tableDataCash[3].length - 2];
                  delete this.tableDataCash[4][this.tableDataCash[4].length - 1];
                  delete this.tableDataCash[5][this.tableDataCash[5].length - 1];
                  delete this.tableDataCash[5][this.tableDataCash[5].length - 2];
                  delete this.tableDataCash[6][this.tableDataCash[6].length - 1];
                  delete this.tableDataCash[7][1];
                  delete this.tableDataCash[8][this.tableDataCash[8].length - 1];
                  delete this.tableDataCash[10][this.tableDataCash[10].length - 1];

                  setTimeout(() => {
                    this.blur_lr()//利润表
                    this.xjl_zje()//现金流
                  }, 2000)
                }
              } else {
                // this.$message({
                //   type: 'success',
                //   message: this.dataObj.Message
                // });
                if (this.dataObj.Data.report_type == "00") {
                  this.balanceSheetMerge = this.dataObj.Data.tableDataSheet;
                  delete this.balanceSheetMerge[0][this.balanceSheetMerge[0].length - 1];
                  delete this.balanceSheetMerge[1][this.balanceSheetMerge[1].length - 1];
                  delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 1];
                  delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 2];
                  delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 1];
                  delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 2];
                  delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 1];
                  delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 2];
                  

                  this.tableDataProfitMerge = this.dataObj.Data.tableDataProfit;
                  delete this.tableDataProfitMerge[0][this.tableDataProfitMerge[0].length - 1];
                  delete this.tableDataProfitMerge[1][this.tableDataProfitMerge[1].length - 1];
                  delete this.tableDataProfitMerge[2][this.tableDataProfitMerge[2].length - 1];
                  delete this.tableDataProfitMerge[3][this.tableDataProfitMerge[3].length - 1];
                  delete this.tableDataProfitMerge[4][this.tableDataProfitMerge[4].length - 1];
                  delete this.tableDataProfitMerge[5][this.tableDataProfitMerge[5].length - 1];
                  delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 1];
                  delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 2];
                  delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 3];
                  delete this.tableDataProfitMerge[7][this.tableDataProfitMerge[7].length - 1];
                  delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 1];
                  delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 2];

                  this.tableDataCashMerge = this.dataObj.Data.tableDataCash;
                  delete this.tableDataCashMerge[0][this.tableDataCashMerge[0].length - 1];
                  delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 1];
                  delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 2];
                  delete this.tableDataCashMerge[2][this.tableDataCashMerge[2].length - 1];
                  delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 1];
                  delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 2];
                  delete this.tableDataCashMerge[4][this.tableDataCashMerge[4].length - 1];
                  delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 1];
                  delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 2];
                  delete this.tableDataCashMerge[6][this.tableDataCashMerge[6].length - 1];
                  delete this.tableDataCashMerge[7][this.tableDataCashMerge[7].length - 1];

                  setTimeout(() => {
                    this.qyhj()//所有者权益
                    this.SummarBlur()//利润表
                    this.xjl_bl()//现金流
                  }, 2000)
                } else {
                  this.balanceSheet = this.dataObj.Data.tableDataSheet;
                  delete this.balanceSheet[0][this.balanceSheet[0].length - 1];
                  delete this.balanceSheet[1][this.balanceSheet[1].length - 1];
                  delete this.balanceSheet[2][this.balanceSheet[2].length - 1];
                  delete this.balanceSheet[2][this.balanceSheet[2].length - 2];
                  delete this.balanceSheet[3][this.balanceSheet[3].length - 1];
                  delete this.balanceSheet[3][this.balanceSheet[3].length - 2];
                  delete this.balanceSheet[4][this.balanceSheet[4].length - 1];
                  delete this.balanceSheet[4][this.balanceSheet[4].length - 2];

                  this.tableDataProfit = this.dataObj.Data.tableDataProfit;

                  delete this.tableDataProfit[0][this.tableDataProfit[0].length - 1];
                  delete this.tableDataProfit[1][this.tableDataProfit[1].length - 1];
                  delete this.tableDataProfit[2][this.tableDataProfit[2].length - 1];
                  delete this.tableDataProfit[3][this.tableDataProfit[3].length - 1];
                  delete this.tableDataProfit[4][this.tableDataProfit[4].length - 1];
                  delete this.tableDataProfit[5][this.tableDataProfit[5].length - 1];

                  this.tableDataCash = this.dataObj.Data.tableDataCash;
                  delete this.tableDataCash[0][this.tableDataCash[0].length - 1];
                  delete this.tableDataCash[1][this.tableDataCash[1].length - 1];
                  delete this.tableDataCash[1][this.tableDataCash[1].length - 2];
                  delete this.tableDataCash[2][this.tableDataCash[2].length - 1];
                  delete this.tableDataCash[3][this.tableDataCash[3].length - 1];
                  delete this.tableDataCash[3][this.tableDataCash[3].length - 2];
                  delete this.tableDataCash[4][this.tableDataCash[4].length - 1];
                  delete this.tableDataCash[5][this.tableDataCash[5].length - 1];
                  delete this.tableDataCash[5][this.tableDataCash[5].length - 2];
                  delete this.tableDataCash[6][this.tableDataCash[6].length - 1];
                  delete this.tableDataCash[7][1];
                  delete this.tableDataCash[8][this.tableDataCash[8].length - 1];
                  delete this.tableDataCash[10][this.tableDataCash[10].length - 1];

                  setTimeout(() => {
                    this.blur_lr()//利润表
                    this.xjl_zje()//现金流
                  }, 2000)
                }
              }
            });
          }
        })

      }
      // 1查看2修改
      if (this.$route.query.type == "1" || this.$route.query.type == "2") {
        let param = {
          fs_no: this.rowData.fs_no,
          company_code: this.company_code,
          report_year: this.rowData.report_year,
          report_time: this.rowData.report_time,
          report_type: this.rowData.report_type,
        };
        getData(param).then((res) => {
          if (res.data.StatementData.report_time == '00') {
            this.BType = res.data.StatementData.report_time;
            this.dateTime = res.data.StatementData.report_year
            this.codetype = res.data.StatementData.report_type;
          } else {
            this.BType = '01'
            this.dateTime = res.data.StatementData.report_year + '-' + res.data.StatementData.report_time.substring(1, 3)
            this.codetype = res.data.StatementData.report_type;
          }

          if (res.data.StatementData.report_type == "00") {
            this.balanceSheetMerge = res.data.StatementData.tableDataSheet;
            delete this.balanceSheetMerge[0][this.balanceSheetMerge[0].length - 1];
            delete this.balanceSheetMerge[1][this.balanceSheetMerge[1].length - 1];
            delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 1];
            delete this.balanceSheetMerge[2][this.balanceSheetMerge[2].length - 2];
            delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 1];
            delete this.balanceSheetMerge[3][this.balanceSheetMerge[3].length - 2];
            delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 1];
            delete this.balanceSheetMerge[4][this.balanceSheetMerge[4].length - 2];

            this.tableDataProfitMerge = res.data.StatementData.tableDataProfit;

            delete this.tableDataProfitMerge[0][this.tableDataProfitMerge[0].length - 1];
            delete this.tableDataProfitMerge[1][this.tableDataProfitMerge[1].length - 1];
            delete this.tableDataProfitMerge[2][this.tableDataProfitMerge[2].length - 1];
            delete this.tableDataProfitMerge[3][this.tableDataProfitMerge[3].length - 1];
            delete this.tableDataProfitMerge[4][this.tableDataProfitMerge[4].length - 1];
            delete this.tableDataProfitMerge[5][this.tableDataProfitMerge[5].length - 1];
            delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 1];
            delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 2];
            delete this.tableDataProfitMerge[6][this.tableDataProfitMerge[6].length - 3];
            delete this.tableDataProfitMerge[7][this.tableDataProfitMerge[7].length - 1];
            delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 1];
            delete this.tableDataProfitMerge[8][this.tableDataProfitMerge[8].length - 2];

            this.tableDataCashMerge = res.data.StatementData.tableDataCash;

            delete this.tableDataCashMerge[0][this.tableDataCashMerge[0].length - 1];
            delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 1];
            delete this.tableDataCashMerge[1][this.tableDataCashMerge[1].length - 2];
            delete this.tableDataCashMerge[2][this.tableDataCashMerge[2].length - 1];
            delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 1];
            delete this.tableDataCashMerge[3][this.tableDataCashMerge[3].length - 2];
            delete this.tableDataCashMerge[4][this.tableDataCashMerge[4].length - 1];
            delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 1];
            delete this.tableDataCashMerge[5][this.tableDataCashMerge[5].length - 2];
            delete this.tableDataCashMerge[6][this.tableDataCashMerge[6].length - 1];
            delete this.tableDataCashMerge[7][this.tableDataCashMerge[7].length - 1];
            setTimeout(() => {
              this.qyhj()//所有者权益
              this.SummarBlur()//利润表
              this.xjl_bl()//现金流
            }, 2000)

          } else {
            this.balanceSheet = res.data.StatementData.tableDataSheet;

            delete this.balanceSheet[0][this.balanceSheet[0].length - 1];
            delete this.balanceSheet[1][this.balanceSheet[1].length - 1];
            delete this.balanceSheet[2][this.balanceSheet[2].length - 1];
            delete this.balanceSheet[2][this.balanceSheet[2].length - 2];
            delete this.balanceSheet[3][this.balanceSheet[3].length - 1];
            delete this.balanceSheet[3][this.balanceSheet[3].length - 2];
            delete this.balanceSheet[4][this.balanceSheet[4].length - 1];
            delete this.balanceSheet[4][this.balanceSheet[4].length - 2];

            this.tableDataProfit = res.data.StatementData.tableDataProfit;

            delete this.tableDataProfit[0][this.tableDataProfit[0].length - 1];
            delete this.tableDataProfit[1][this.tableDataProfit[1].length - 1];
            delete this.tableDataProfit[2][this.tableDataProfit[2].length - 1];
            delete this.tableDataProfit[3][this.tableDataProfit[3].length - 1];
            delete this.tableDataProfit[4][this.tableDataProfit[4].length - 1];
            delete this.tableDataProfit[5][this.tableDataProfit[5].length - 1];

            this.tableDataCash = res.data.StatementData.tableDataCash;
            delete this.tableDataCash[0][this.tableDataCash[0].length - 1];
            delete this.tableDataCash[1][this.tableDataCash[1].length - 1];
            delete this.tableDataCash[1][this.tableDataCash[1].length - 2];
            delete this.tableDataCash[2][this.tableDataCash[2].length - 1];
            delete this.tableDataCash[3][this.tableDataCash[3].length - 1];
            delete this.tableDataCash[3][this.tableDataCash[3].length - 2];
            delete this.tableDataCash[4][this.tableDataCash[4].length - 1];
            delete this.tableDataCash[5][this.tableDataCash[5].length - 1];
            delete this.tableDataCash[5][this.tableDataCash[5].length - 2];
            delete this.tableDataCash[6][this.tableDataCash[6].length - 1];
            delete this.tableDataCash[7][1];
            delete this.tableDataCash[8][this.tableDataCash[8].length - 1];
            delete this.tableDataCash[10][this.tableDataCash[10].length - 1];

            setTimeout(() => {
              this.blur_lr()//利润表
              this.xjl_zje()//现金流
            }, 2000)

          }
        });
      }
      if (this.$route.query.type == "3") {
        let data = sesstion.getLocalData("TimeList")
        this.editTimeData = data.change_log
        this.editTime = data.change_log[0].log_id
        this.editTimeChange(this.editTime)
      }
    },


    focusIndex(e) {
      this.nextNum = e.target.getAttribute('data-index')
      // console.log(this.nextNum);
    },
    initInputDOM() {
      // 获取id为table-form下的所有input 框
      const inputDoms = document.querySelectorAll('.el-input__inner')
      // 遍历这个input框给他们一个标识
      inputDoms.forEach((item, index) => {
        item.setAttribute('data-index', index)
      })
      this.inputDoms = inputDoms
      // console.log(this.inputDoms);
    },

    // 回车事件
    nextFocus(event) {
      const index = event.target.getAttribute('data-index')
      const nextIndex = parseInt(index) + 2
      const length = this.inputDoms.length
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus()
        this.inputDoms[nextIndex].select()
      } else {
        this.inputDoms[0].focus()
        this.inputDoms[nextIndex].select()
      }
    },

    // 重置当前页
    reset(type) {
      // 一般
      if (type == 1) {
        this.balanceSheet.map((item, index) => {
          item.map((it, i) => {
            it.value1 = "";
            it.value2 = "";
          });
        });
      }
      if (type == 2) {
        this.tableDataProfit.map((item, index) => {
          item.map((it, i) => {
            it.value1 = "";
            it.value2 = "";
          });
        });
      }
      if (type == 3) {
        this.tableDataCash.map((item, index) => {
          item.map((it, i) => {
            it.value1 = "";
            it.value2 = "";
          });
        });
      }
      // 合并
      if (type == "01") {
        this.balanceSheetMerge.map((item, index) => {
          item.map((it, i) => {
            it.value1 = "";
            it.value2 = "";
          });
        });
      }
      if (type == "02") {
        this.tableDataProfitMerge.map((item, index) => {
          item.map((it, i) => {
            it.value1 = "";
            it.value2 = "";
          });
        });
      }
      if (type == "03") {
        this.tableDataCashMerge.map((item, index) => {
          item.map((it, i) => {
            it.value1 = "";
            it.value2 = "";
          });
        });
      }
    },
    // 保存当前页
    present(type) {
      if (this.codetype == "01") {
        // 资产负债表添加合计项
        let balance = JSON.parse(JSON.stringify(this.balanceSheet)); //不解释

        balance[0].push({
          name: this.TotalCurrentAssets[0],
          value1: this.TotalCurrentAssets[2],
          value2: this.TotalCurrentAssets[1],
          code: "1019h",
        });
        balance[1].push({
          name: this.TotalCurrentLiability[0],
          value1: this.TotalCurrentLiability[2],
          value2: this.TotalCurrentLiability[1],
          code: "1061h",
        });
        balance[2].push(
          {
            name: this.FlagTotalCurrentAssets[0],
            value1: this.FlagTotalCurrentAssets[2],
            value2: this.FlagTotalCurrentAssets[1],
            code: "1254h",
          },
          {
            name: "资产总计",
            value1: Number(this.TotalCurrentAssets[2]) +
              Number(this.FlagTotalCurrentAssets[2]),

            value2: Number(this.TotalCurrentAssets[1]) +
              Number(this.FlagTotalCurrentAssets[1]),
            code: "1046h",
          }
        );
        balance[3].push(
          {
            name: this.FlagTotalCurrentLiability[0],
            value1: this.FlagTotalCurrentLiability[2],
            value2: this.FlagTotalCurrentLiability[1],
            code: "1266h",
          },
          {
            name: "负债合计",
            value1: Number(this.TotalCurrentLiability[2]) +
              Number(this.FlagTotalCurrentLiability[2]),

            value2: Number(this.TotalCurrentLiability[1]) +
              Number(this.FlagTotalCurrentLiability[1]),

            code: "1069h",
          }
        );
        balance[4].push(
          {
            name: this.ownershipInterest[0],
            value1: this.ownershipInterest[2],
            value2: this.ownershipInterest[1],
            code: "1076h",
          },
          {
            name: "负债和所有者权益(或股东权益)合计",
            value1:
              Number(this.TotalCurrentLiability[2]) +
              Number(this.FlagTotalCurrentLiability[2]) +
              Number(this.ownershipInterest[2]),
            value2:
              Number(this.TotalCurrentLiability[1]) +
              Number(this.FlagTotalCurrentLiability[1]) +
              Number(this.ownershipInterest[1]),
            code: "1617h",
          }
        );
        let Profit = JSON.parse(JSON.stringify(this.tableDataProfit)); //不解释/
        Profit[0].push({
          name: this.operatingProfit[0],
          value1: this.operatingProfit[1],
          value2: this.operatingProfit[2],
          code: "1630",
        });
        Profit[1].push({
          name: this.TotalProfit[0],
          value1: this.TotalProfit[1],
          value2: this.TotalProfit[2],
          code: "1096",
        });
        Profit[2].push({
          name: this.retained[0],
          value1: this.retained[1],
          value2: this.retained[2],
          code: "1099",
        });
        Profit[3].push({
          name: "五、其他综合收益的税后净额",
          value1: Number(this.statement[1]) + Number(this.reclassification[1]),
          value2: Number(this.statement[2]) + Number(this.reclassification[2]),
          code: "1827",
        });
        Profit[4].push({
          name: this.statement[0],
          value1: this.statement[1],
          value2: this.statement[2],
          code: "1828",
        });
        Profit[5].push({
          name: this.reclassification[0],
          value1: this.reclassification[1],
          value2: this.reclassification[2],
          code: "1833",
        });
        // console.log(Profit);
        let Activities = JSON.parse(JSON.stringify(this.tableDataCash)); //不解释
        Activities[0].push({
          name: this.getActivitiesTotal[0],
          value1: this.getActivitiesTotal[1],
          value2: this.getActivitiesTotal[2],
          code: "1295h",
        });
        Activities[1].push(
          {
            name: this.getActivitiesTotalOut[0],
            value1: this.getActivitiesTotalOut[1],
            value2: this.getActivitiesTotalOut[2],
            code: "1298h",
          },
          {
            name: "经营活动产生的现金流量净额",
            value1: Number(this.getActivitiesTotal[1]) - Number(this.getActivitiesTotalOut[1]),
            value2: Number(this.getActivitiesTotal[2]) - Number(this.getActivitiesTotalOut[2]),
            code: "1109h",
          }
        );
        Activities[2].push({
          name: this.investmentTotals[0],
          value1: this.investmentTotals[1],
          value2: this.investmentTotals[2],
          code: "1114h",
        });
        Activities[3].push(
          {
            name: this.investmentOut[0],
            value1: this.investmentOut[1],
            value2: this.investmentOut[2],
            code: "1118h",
          },
          {
            name: "投资活动产生的现金流量净额",
            value1: Number(this.investmentTotals[1]) - Number(this.investmentOut[1]),
            value2: Number(this.investmentTotals[2]) - Number(this.investmentOut[2]),
            code: "1119h",
          }
        );
        Activities[4].push({
          name: this.getSummariesTotal[0],
          value1: this.getSummariesTotal[1],
          value2: this.getSummariesTotal[2],
          code: "1123h",
        });
        Activities[5].push(
          {
            name: this.getSummariesOut[0],
            value1: this.getSummariesOut[1],
            value2: this.getSummariesOut[2],
            code: "1127h",
          },
          {
            name: "筹资活动产生的现金流量净额",
            value1: Number(this.getSummariesTotal[1]) - Number(this.getSummariesOut[1]),
            value2: Number(this.getSummariesTotal[2]) - Number(this.getSummariesOut[2]),
            code: "1313h",
          }
        );
        Activities[6].push({
          name: this.getTexchange[0],
          value1: this.getTexchange[1],
          value2: this.getTexchange[2],
          code: "1130",
        });
        Activities[7].push({
          name: this.getTexchanges[0],
          value1: this.getTexchanges[1],
          value2: this.getTexchanges[2],
          code: "1653h",
        });
        // 补充
        Activities[8].push({
          name: this.supplementToken[0],
          value1: this.supplementToken[1],
          value2: this.supplementToken[2],
          code: "xjllb55",
        });
        Activities[10].push({
          name: this.supplementsToken[0],
          value1: this.supplementsToken[1],
          value2: this.supplementsToken[2],
          code: "xjllb65",
        });
        // console.log(Activities);
        if (this.BType == '00') {
          var param = {
            company_code: this.company_code,
            report_year: this.dateTime.substring(0, 4),
            report_time: this.BType,
            report_type: this.codetype,
            tableDataSheet: balance,
            tableDataCash: Activities,
            tableDataProfit: Profit,
          };
        } else {
          var param = {
            company_code: this.company_code,
            report_year: this.dateTime.substring(0, 4),
            report_time: '0' + this.dateTime.substring(5, 7),
            report_type: this.codetype,
            tableDataSheet: balance,
            tableDataProfit: Profit,
            tableDataCash: Activities,
          };
        }

        
        hand_save_cache(param).then((res) => {
          if (res.data.Status == 1) {
            this.$Message.success(res.data.Message)
          } else {
            this.$Message.error(res.data.Message)
          }
        })
      } else {
        let balance = JSON.parse(JSON.stringify(this.balanceSheetMerge)); //不解释
        balance[0].push({
          name: this.TotalCurrentAssetss[0],
          value1: Number(this.TotalCurrentAssetss[1]),
          value2: Number(this.TotalCurrentAssetss[2]),
          code: "1019h",
        });
        balance[1].push({
          name: this.TotalCurrentLiabilitys[0],
          value1: this.TotalCurrentLiabilitys[1],
          value2: this.TotalCurrentLiabilitys[2],
          code: "1061h",
        });
        balance[2].push(
          {
            name: this.FlagTotalCurrentAssetss[0],
            value1: this.FlagTotalCurrentAssetss[1],
            value2: this.FlagTotalCurrentAssetss[2],
            code: "1254h",
          },
          {
            name: "资产总计",
            value1:
              Number(this.TotalCurrentAssetss[1]) +
              Number(this.FlagTotalCurrentAssetss[1]),
            value2:
              Number(this.TotalCurrentAssetss[2]) +
              Number(this.FlagTotalCurrentAssetss[2]),
            code: "1046h",
          }
        );
        balance[3].push(
          {
            name: this.FlagTotalCurrentLiabilitys[0],
            value1: this.FlagTotalCurrentLiabilitys[1],
            value2: this.FlagTotalCurrentLiabilitys[2],
            code: "1266h",
          },
          {
            name: "负债合计",
            value1:
              Number(this.TotalCurrentLiabilitys[1]) +
              Number(this.FlagTotalCurrentLiabilitys[1]),
            value2:
              Number(this.TotalCurrentLiabilitys[2]) +
              Number(this.FlagTotalCurrentLiabilitys[2]),
            code: "1069h",
          }
        );
        balance[4].push(
          {
            name: this.ownershipInterests[0],
            value1: this.ownershipInterests[1],
            value2: this.ownershipInterests[2],
            code: "1076h",
          },
          {
            name: "负债和所有者权益(或股东权益)合计",
            value1:
              Number(this.TotalCurrentLiabilitys[1]) +
              Number(this.FlagTotalCurrentLiabilitys[1]) +
              Number(this.ownershipInterests[1]),
            value2:
              Number(this.TotalCurrentLiabilitys[2]) +
              Number(this.FlagTotalCurrentLiabilitys[2]) +
              Number(this.ownershipInterests[2]),
            code: "1617h",
          }
        );
        // console.log(balance);
        // 利润表
        let Profit = JSON.parse(JSON.stringify(this.tableDataProfitMerge)); //不解释
        Profit[0].push({
          name: this.operatingProfits[0],
          value1: this.operatingProfits[1],
          value2: this.operatingProfits[2],
          code: "1618h",
        });
        Profit[1].push({
          name: this.TotalProfits[0],
          value1: this.TotalProfits[1],
          value2: this.TotalProfits[2],
          code: "1624h",
        });
        Profit[2].push({
          name: this.retaineds[0],
          value1: this.retaineds[1],
          value2: this.retaineds[2],
          code: "1630",
        });
        Profit[3].push({
          name: this.statements[0],
          value1: this.statements[1],
          value2: this.statements[2],
          code: "1096",
        });
        Profit[4].push({
          name: this.statementsact[0],
          value1: this.statementsact[1],
          value2: this.statementsact[2],
          code: "1099",
        });
        Profit[5].push({
          name: this.SummarieClass[0],
          value1: this.SummarieClass[1],
          value2: this.SummarieClass[2],
          code: "1909n",
        });
        Profit[6].push(
          {
            name: this.SummarieClasss[0],
            value1: this.SummarieClasss[1],
            value2: this.SummarieClasss[2],
            code: "1908n",
          },
          {
            name: "六、其他综合收益的税后净额",
            value1: Number(this.noClass[1]) + Number(this.noClasss[1]),
            value2: Number(this.noClass[1]) + Number(this.noClasss[1]),
            code: "1827",
          },
          {
            name: "（一）归属于母公司所有者的其他综合收益的税后净额",
            value1: Number(this.noClass[1]) + Number(this.noClasss[1]),
            value2: Number(this.noClass[1]) + Number(this.noClasss[1]),
            code: "1724n",
          }
        );
        Profit[7].push({
          name: this.noClass[0],
          value1: this.noClass[1],
          value2: this.noClass[2],
          code: "1828",
        });
        Profit[8].push(
          {
            name: this.noClasss[0],
            value1: this.noClasss[1],
            value2: this.noClasss[2],
            code: "1833",
          },
          {
            name: "七、综合收益总额",
            value1: Number(this.statementsact[1]) + Number(this.noClass[1]) + Number(this.noClasss[1]),
            value2: Number(this.statementsact[2]) + Number(this.noClass[2]) + Number(this.noClasss[2]),
            code: "1639",
          }
        );
        // console.log(Profit);
        let Activities = JSON.parse(JSON.stringify(this.tableDataCashMerge)); //不解释
        Activities[0].push({
          name: this.getActivitiesTotals[0],
          value1: this.getActivitiesTotals[1],
          value2: this.getActivitiesTotals[2],
          code: "1295h",
        });
        Activities[1].push(
          {
            name: this.getActivitiesTotalOuts[0],
            value1: this.getActivitiesTotalOuts[1],
            value2: this.getActivitiesTotalOuts[2],
            code: "1298h",
          },
          {
            name: "经营活动产生的现金流量净额",
            value1:
              Number(this.getActivitiesTotals[1]) - Number(this.getActivitiesTotalOuts[1]),
            value2:
              Number(this.getActivitiesTotals[2]) - Number(this.getActivitiesTotalOuts[2]),
            code: "1109h",
          }
        );
        Activities[2].push({
          name: this.investmentTotalss[0],
          value1: this.investmentTotalss[1],
          value2: this.investmentTotalss[2],
          code: "1114h",
        });
        Activities[3].push(
          {
            name: this.investmentOuts[0],
            value1: this.investmentOuts[1],
            value2: this.investmentOuts[2],
            code: "1118h",
          },
          {
            name: "投资活动产生的现金流量净额",
            value1: Number(this.investmentTotalss[1]) - Number(this.investmentOuts[1]),
            value2: Number(this.investmentTotalss[2]) - Number(this.investmentOuts[2]),
            code: "1119h",
          }
        );
        Activities[4].push({
          name: this.getSummariesTotals[0],
          value1: this.getSummariesTotals[1],
          value2: this.getSummariesTotals[2],
          code: "1123h",
        });
        Activities[5].push(
          {
            name: this.getSummariesOuts[0],
            value1: this.getSummariesOuts[1],
            value2: this.getSummariesOuts[2],
            code: "1127h",
          },
          {
            name: "筹资活动产生的现金流量净额",
            value1: Number(this.getSummariesTotals[1]) - Number(this.getSummariesOuts[1]),
            value2: Number(this.getSummariesTotals[2]) - Number(this.getSummariesOuts[2]),
            code: "1313h",
          }
        );
        Activities[6].push({
          name: this.getTexchanges[0],
          value1: this.getTexchanges[1],
          value2: this.getTexchanges[2],
          code: "1130",
        });
        Activities[7].push({
          name: this.getTexchangess[0],
          value1: this.getTexchangess[1],
          value2: this.getTexchangess[2],
          code: "1653h",
        });
        // 补充
        Activities[8].push({
          name: this.supplementToken[0],
          value1: this.supplementToken[1],
          value2: this.supplementToken[2],
          code: 'xjllb55',
        })
        Activities[10].push({
          name: this.supplementsToken[0],
          value1: this.supplementsToken[1],
          value2: this.supplementsToken[2],
          code: 'xjllb65',
        })
        // console.log(Activities);

        if (this.BType == '00') {
          var param = {
            company_code: this.company_code,
            report_year: this.dateTime.substring(0, 4),
            report_time: this.BType,
            report_type: this.codetype,
            tableDataSheet: balance,
            tableDataCash: Activities,
            tableDataProfit: Profit,
          };
        } else {
          var param = {
            company_code: this.company_code,
            report_year: this.dateTime.substring(0, 4),
            report_time: '0' + this.dateTime.substring(5, 7),
            report_type: this.codetype,
            tableDataSheet: balance,
            tableDataCash: Activities,
            tableDataProfit: Profit,
          };
        }

        
        hand_save_cache(param).then((res) => {
          if (res.data.Status == 1) {
            this.$Message.success(res.data.Message)
          } else {
            this.$Message.error(res.data.Message)
          }
        })
      }
    },
    numFilter(value) {
      // console.log(value);
      if (value !== 0) {
        let realVal = Number(value).toFixed(2);
        return realVal;
      }
    },
    // 提交表单数据
    onDialogVisible() {
      // 报表
      if (this.codetype == "01") {
        // 资产负债表添加合计项
        let balance = JSON.parse(JSON.stringify(this.balanceSheet)); //不解释
        balance[0].push({
          name: this.TotalCurrentAssets[0],
          value1: this.TotalCurrentAssets[2],
          value2: this.TotalCurrentAssets[1],
          code: "1019h",
        });
        balance[1].push({
          name: this.TotalCurrentLiability[0],
          value1: this.TotalCurrentLiability[2],
          value2: this.TotalCurrentLiability[1],
          code: "1061h",
        });
        balance[2].push(
          {
            name: this.FlagTotalCurrentAssets[0],
            value1: this.FlagTotalCurrentAssets[2],
            value2: this.FlagTotalCurrentAssets[1],
            code: "1254h",
          },
          {
            name: "资产总计",
            value1: Number(this.TotalCurrentAssets[2]) +
              Number(this.FlagTotalCurrentAssets[2]),

            value2: Number(this.TotalCurrentAssets[1]) +
              Number(this.FlagTotalCurrentAssets[1]),
            code: "1046h",
          }
        );
        balance[3].push(
          {
            name: this.FlagTotalCurrentLiability[0],
            value1: this.FlagTotalCurrentLiability[2],
            value2: this.FlagTotalCurrentLiability[1],
            code: "1266h",
          },
          {
            name: "负债合计",
            value1: Number(this.TotalCurrentLiability[2]) +
              Number(this.FlagTotalCurrentLiability[2]),

            value2: Number(this.TotalCurrentLiability[1]) +
              Number(this.FlagTotalCurrentLiability[1]),

            code: "1069h",
          }
        );
        balance[4].push(
          {
            name: this.ownershipInterest[0],
            value1: this.ownershipInterest[2],
            value2: this.ownershipInterest[1],
            code: "1076h",
          },
          {
            name: "负债和所有者权益(或股东权益)合计",
            value1:
              Number(this.TotalCurrentLiability[2]) +
              Number(this.FlagTotalCurrentLiability[2]) +
              Number(this.ownershipInterest[2]),
            value2:
              Number(this.TotalCurrentLiability[1]) +
              Number(this.FlagTotalCurrentLiability[1]) +
              Number(this.ownershipInterest[1]),
            code: "1617h",
          }
        );
        // console.log(balance);
        let Profit = JSON.parse(JSON.stringify(this.tableDataProfit)); //不解释
        Profit[0].push({
          name: this.operatingProfit[0],
          value1: this.operatingProfit[1],
          value2: this.operatingProfit[2],
          code: "1630",
        });
        Profit[1].push({
          name: this.TotalProfit[0],
          value1: this.TotalProfit[1],
          value2: this.TotalProfit[2],
          code: "1096",
        });
        Profit[2].push({
          name: this.retained[0],
          value1: this.retained[1],
          value2: this.retained[2],
          code: "1099",
        });
        Profit[3].push({
          name: "五、其他综合收益的税后净额",
          value1: Number(this.statement[1]) + Number(this.reclassification[1]),
          value2: Number(this.statement[2]) + Number(this.reclassification[2]),
          code: "1827",
        });
        Profit[4].push({
          name: this.statement[0],
          value1: this.statement[1],
          value2: this.statement[2],
          code: "1828",
        });
        Profit[5].push({
          name: this.reclassification[0],
          value1: this.reclassification[1],
          value2: this.reclassification[2],
          code: "1833",
        });
        // console.log(Profit);
        let Activities = JSON.parse(JSON.stringify(this.tableDataCash)); //不解释
        Activities[0].push({
          name: this.getActivitiesTotal[0],
          value1: this.getActivitiesTotal[1],
          value2: this.getActivitiesTotal[2],
          code: "1295h",
        });
        Activities[1].push(
          {
            name: this.getActivitiesTotalOut[0],
            value1: this.getActivitiesTotalOut[1],
            value2: this.getActivitiesTotalOut[2],
            code: "1298h",
          },
          {
            name: "经营活动产生的现金流量净额",
            value1: Number(this.getActivitiesTotal[1]) - Number(this.getActivitiesTotalOut[1]),
            value2: Number(this.getActivitiesTotal[2]) - Number(this.getActivitiesTotalOut[2]),
            code: "1109h",
          }
        );
        Activities[2].push({
          name: this.investmentTotals[0],
          value1: this.investmentTotals[1],
          value2: this.investmentTotals[2],
          code: "1114h",
        });
        Activities[3].push(
          {
            name: this.investmentOut[0],
            value1: this.investmentOut[1],
            value2: this.investmentOut[2],
            code: "1118h",
          },
          {
            name: "投资活动产生的现金流量净额",
            value1: Number(this.investmentTotals[1]) - Number(this.investmentOut[1]),
            value2: Number(this.investmentTotals[2]) - Number(this.investmentOut[2]),
            code: "1119h",
          }
        );
        Activities[4].push({
          name: this.getSummariesTotal[0],
          value1: this.getSummariesTotal[1],
          value2: this.getSummariesTotal[2],
          code: "1123h",
        });
        Activities[5].push(
          {
            name: this.getSummariesOut[0],
            value1: this.getSummariesOut[1],
            value2: this.getSummariesOut[2],
            code: "1127h",
          },
          {
            name: "筹资活动产生的现金流量净额",
            value1: Number(this.getSummariesTotal[1]) - Number(this.getSummariesOut[1]),
            value2: Number(this.getSummariesTotal[2]) - Number(this.getSummariesOut[2]),
            code: "1313h",
          }
        );
        Activities[6].push({
          name: this.getTexchange[0],
          value1: this.getTexchange[1],
          value2: this.getTexchange[2],
          code: "1130",
        });
        Activities[7].push({
          name: this.getTexchanges[0],
          value1: this.getTexchanges[1],
          value2: this.getTexchanges[2],
          code: "1653h",
        });
        // 补充
        Activities[8].push({
          name: this.supplementToken[0],
          value1: this.supplementToken[1],
          value2: this.supplementToken[2],
          code: "xjllb55",
        });
        Activities[10].push({
          name: this.supplementsToken[0],
          value1: this.supplementsToken[1],
          value2: this.supplementsToken[2],
          code: "xjllb65",
        });
        if (this.$route.query.type == "2") {
          var param = {}
          if (this.BType == '00') {
            param = {
              fs_no: this.rowData.fs_no,
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: this.BType,
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
          } else {
            param = {
              fs_no: this.rowData.fs_no,
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: '0' + this.dateTime.substring(5, 7),
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
          }

        } else {
          var param = {}
          // console.log(this.BType);
          if (this.BType == '00') {
            param = {
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: this.BType,
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
          } else {
            param = {
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: '0' + this.dateTime.substring(5, 7),
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
            
          }
        }
        hand_save(param).then((res) => {
          if (res.data.Status == 1) {
            this.$Message.success(res.data.Message);
            this.dialogVisible = false;
            if (res.data.Data.is_popover) {
              this.sheet = true
              sesstion.setLocalData("sheet", res.data.Data)
            } else {
              setTimeout(() => {
                this.$router.push("/reportManagement");
              }, 1000);
            }
          } else {
            this.$notify.error({
              title: '提示',
              message: res.data.Message
            });
            this.dialogVisible = false;
          }
        });
      } else {
        // 合并报表
        // console.log("这是合并表");
        // 资产负债表添加合计项
        let balance = JSON.parse(JSON.stringify(this.balanceSheetMerge)); //不解释
        balance[0].push({
          name: this.TotalCurrentAssetss[0],
          value1: this.TotalCurrentAssetss[2],
          value2: this.TotalCurrentAssetss[1],
          code: "1019h",
        });
        balance[1].push({
          name: this.TotalCurrentLiabilitys[0],
          value1: this.TotalCurrentLiabilitys[2],
          value2: this.TotalCurrentLiabilitys[1],
          code: "1061h",
        });
        balance[2].push(
          {
            name: this.FlagTotalCurrentAssetss[0],
            value1: this.FlagTotalCurrentAssetss[2],
            value2: this.FlagTotalCurrentAssetss[1],
            code: "1254h",
          },
          {
            name: "资产总计",
            value1:
              Number(this.TotalCurrentAssetss[2]) +
              Number(this.FlagTotalCurrentAssetss[2]),
            value2:
              Number(this.TotalCurrentAssetss[1]) +
              Number(this.FlagTotalCurrentAssetss[1]),
            code: "1046h",
          }
        );
        balance[3].push(
          {
            name: this.FlagTotalCurrentLiabilitys[0],
            value1: this.FlagTotalCurrentLiabilitys[2],
            value2: this.FlagTotalCurrentLiabilitys[1],
            code: "1266h",
          },
          {
            name: "负债合计",
            value1:
              Number(this.TotalCurrentLiabilitys[2]) +
              Number(this.FlagTotalCurrentLiabilitys[2]),
            value2:
              Number(this.TotalCurrentLiabilitys[1]) +
              Number(this.FlagTotalCurrentLiabilitys[1]),
            code: "1069h",
          }
        );
        balance[4].push(
          {
            name: this.ownershipInterests[0],
            value1: this.ownershipInterests[2],
            value2: this.ownershipInterests[1],
            code: "1076h",
          },
          {
            name: "负债和所有者权益(或股东权益)合计",
            value1:
              Number(this.TotalCurrentLiabilitys[2]) +
              Number(this.FlagTotalCurrentLiabilitys[2]) +
              Number(this.ownershipInterests[2]),
            value2:
              Number(this.TotalCurrentLiabilitys[1]) +
              Number(this.FlagTotalCurrentLiabilitys[1]) +
              Number(this.ownershipInterests[1]),
            code: "1617h",
          }
        );
        // console.log(balance);
        // 利润表
        let Profit = JSON.parse(JSON.stringify(this.tableDataProfitMerge)); //不解释
        Profit[0].push({
          name: this.operatingProfits[0],
          value1: this.operatingProfits[1],
          value2: this.operatingProfits[2],
          code: "1618h",
        });
        Profit[1].push({
          name: this.TotalProfits[0],
          value1: this.TotalProfits[1],
          value2: this.TotalProfits[2],
          code: "1624h",
        });
        Profit[2].push({
          name: this.retaineds[0],
          value1: this.retaineds[1],
          value2: this.retaineds[2],
          code: "1630",
        });
        Profit[3].push({
          name: this.statements[0],
          value1: this.statements[1],
          value2: this.statements[2],
          code: "1096",
        });
        Profit[4].push({
          name: this.statementsact[0],
          value1: this.statementsact[1],
          value2: this.statementsact[2],
          code: "1099",
        });
        Profit[5].push({
          name: this.SummarieClass[0],
          value1: this.SummarieClass[1],
          value2: this.SummarieClass[2],
          code: "1909n",
        });
        Profit[6].push(
          {
            name: this.SummarieClasss[0],
            value1: this.SummarieClasss[1],
            value2: this.SummarieClasss[2],
            code: "1908n",
          },
          {
            name: "六、其他综合收益的税后净额",
            value1: Number(this.noClass[1]) + Number(this.noClasss[1]),
            value2: Number(this.noClass[1]) + Number(this.noClasss[1]),
            code: "1827",
          },
          {
            name: "（一）归属于母公司所有者的其他综合收益的税后净额",
            value1: Number(this.noClass[1]) + Number(this.noClasss[1]),
            value2: Number(this.noClass[1]) + Number(this.noClasss[1]),
            code: "1724n",
          }
        );
        Profit[7].push({
          name: this.noClass[0],
          value1: this.noClass[1],
          value2: this.noClass[2],
          code: "1828",
        });
        Profit[8].push(
          {
            name: this.noClasss[0],
            value1: this.noClasss[1],
            value2: this.noClasss[2],
            code: "1833",
          },
          {
            name: "七、综合收益总额",
            value1: Number(this.statementsact[1]) + Number(this.noClass[1]) + Number(this.noClasss[1]),
            value2: Number(this.statementsact[2]) + Number(this.noClass[2]) + Number(this.noClasss[2]),
            code: "1639",
          }
        );
        // console.log(Profit);
        let Activities = JSON.parse(JSON.stringify(this.tableDataCashMerge)); //不解释
        Activities[0].push({
          name: this.getActivitiesTotals[0],
          value1: this.getActivitiesTotals[1],
          value2: this.getActivitiesTotals[2],
          code: "1295h",
        });
        Activities[1].push(
          {
            name: this.getActivitiesTotalOuts[0],
            value1: this.getActivitiesTotalOuts[1],
            value2: this.getActivitiesTotalOuts[2],
            code: "1298h",
          },
          {
            name: "经营活动产生的现金流量净额",
            value1:
              Number(this.getActivitiesTotals[1]) - Number(this.getActivitiesTotalOuts[1]),
            value2:
              Number(this.getActivitiesTotals[2]) - Number(this.getActivitiesTotalOuts[2]),
            code: "1109h",
          }
        );
        Activities[2].push({
          name: this.investmentTotalss[0],
          value1: this.investmentTotalss[1],
          value2: this.investmentTotalss[2],
          code: "1114h",
        });
        Activities[3].push(
          {
            name: this.investmentOuts[0],
            value1: this.investmentOuts[1],
            value2: this.investmentOuts[2],
            code: "1118h",
          },
          {
            name: "投资活动产生的现金流量净额",
            value1: Number(this.investmentTotalss[1]) - Number(this.investmentOuts[1]),
            value2: Number(this.investmentTotalss[2]) - Number(this.investmentOuts[2]),
            code: "1119h",
          }
        );
        Activities[4].push({
          name: this.getSummariesTotals[0],
          value1: this.getSummariesTotals[1],
          value2: this.getSummariesTotals[2],
          code: "1123h",
        });
        Activities[5].push(
          {
            name: this.getSummariesOuts[0],
            value1: this.getSummariesOuts[1],
            value2: this.getSummariesOuts[2],
            code: "1127h",
          },
          {
            name: "筹资活动产生的现金流量净额",
            value1: Number(this.getSummariesTotals[1]) - Number(this.getSummariesOuts[1]),
            value2: Number(this.getSummariesTotals[2]) - Number(this.getSummariesOuts[2]),
            code: "1313h",
          }
        );
        Activities[6].push({
          name: this.getTexchanges[0],
          value1: this.getTexchanges[1],
          value2: this.getTexchanges[2],
          code: "1130",
        });
        Activities[7].push({
          name: this.getTexchangess[0],
          value1: this.getTexchangess[1],
          value2: this.getTexchangess[2],
          code: "1653h",
        });
        // 补充
        Activities[8].push({
          name: this.supplementToken[0],
          value1: this.supplementToken[1],
          value2: this.supplementToken[2],
          code: 'xjllb55',
        })
        Activities[10].push({
          name: this.supplementsToken[0],
          value1: this.supplementsToken[1],
          value2: this.supplementsToken[2],
          code: 'xjllb65',
        })


        if (this.$route.query.type == "2") {
          var param = {}
          if (this.BType == '00') {
            param = {
              fs_no: this.rowData.fs_no,
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: this.BType,
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
          } else {
            param = {
              fs_no: this.rowData.fs_no,
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: '0' + this.dateTime.substring(5, 7),
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
          }

        } else {
          var param = {}
          // console.log(this.BType);
          if (this.BType == '00') {
            param = {
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: this.BType,
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
          } else {
            param = {
              company_code: this.company_code,
              report_year: this.dateTime.substring(0, 4),
              report_time: '0' + this.dateTime.substring(5, 7),
              report_type: this.codetype,
              tableDataSheet: balance,
              tableDataProfit: Profit,
              tableDataCash: Activities,
            };
            
          }
        }
        
        hand_save(param).then((res) => {
          
          if (res.data.Status == 1) {
            this.$Message.success(res.data.Message);
            this.dialogVisible = false;
            if (res.data.Data.is_popover) {
              this.sheet = true
              sesstion.setLocalData("sheet", res.data.Data)
            } else {
              setTimeout(() => {
                this.$router.push("/reportManagement");
              }, 1000);
            }
          } else {
            this.$notify.error({
              title: '提示',
              message: res.data.Message
            });
            this.dialogVisible = false;
          }
        });
      }
    },



    onSheet() {
      this.$router.push('/balanceSheet')
    },

    // tab切换
    handleClick(tab, event) { },
    // 单体资产负债表合计项
    getSummariesOne(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.TotalCurrentAssets[index] = "流动资产合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          that.TotalCurrentAssets[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.TotalCurrentAssets[index];
        } else {
          that.TotalCurrentAssets[index] = 0;
        }
      });
      that.$forceUpdate();
      console.log(that.TotalCurrentAssets);
      return that.TotalCurrentAssets;
    },
    getSummariesTwo(param) {
      const { columns, data } = param;
      // const sums = [];
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.TotalCurrentLiability[index] = "流动负债合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.TotalCurrentLiability[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.TotalCurrentLiability[index];
        } else {
          that.TotalCurrentLiability[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.TotalCurrentLiability;
    },
    getSummariesThree(param) {
      const { columns, data } = param;
      // const sums = [];
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.FlagTotalCurrentAssets[index] = "非流动资产合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.FlagTotalCurrentAssets[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.FlagTotalCurrentAssets[index];
        } else {
          that.FlagTotalCurrentAssets[index] = 0;
        }
      });

      that.$forceUpdate();
      return that.FlagTotalCurrentAssets;
    },
    getSummariesFour(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.FlagTotalCurrentLiability[index] = "非流动负债合计";
          return;
        }
        const values = data.map((item, i) => Number(item[column.property]));
        if (index === 1) {
          var num = values[1] +
            values[2] +
            values[5] +
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10]
          if (num !== 0) {
            that.FlagTotalCurrentLiability[index] = parseFloat(num).toFixed(2);
          } else {
            that.FlagTotalCurrentLiability[index] = '0.00';
          }
        }
        if (index === 2) {
          var num = values[1] +
            values[2] +
            values[5] +
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10]
          if (num !== 0) {
            that.FlagTotalCurrentLiability[index] = parseFloat(num).toFixed(2);
          } else {
            that.FlagTotalCurrentLiability[index] = '0.00';
          }
        }
      });
      that.$forceUpdate();
      return that.FlagTotalCurrentLiability;
    },
    getSummariesFive(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.ownershipInterest[index] = "所有者权益(或股东权益)合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (index == 1) {
          var num =
            values[1] +
            values[2] +
            values[5] -
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10];
          if (num !== 0) {
            that.ownershipInterest[index] = parseFloat(num).toFixed(2)
          } else {
            that.ownershipInterest[index] = '0.00';
          }
        }
        if (index == 2) {
          var num =
            values[1] +
            values[2] +
            values[5] -
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10];
          if (num !== 0) {
            that.ownershipInterest[index] = parseFloat(num).toFixed(2)
          } else {
            that.ownershipInterest[index] = '0.00';
          }
        }
      });
      that.$forceUpdate();
      return that.ownershipInterest;
    },



    // 单体利润表计算合计  营业利润
    getSummariesSix(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        // console.log(column,index);
        if (index === 0) {
          that.operatingProfit[index] = "二、营业利润（亏损以“-”号填列）";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num =
            values[0] -
            values[1] -
            values[2] -
            values[3] -
            values[4] -
            values[5] -
            values[6] +
            values[9] +
            values[10] +
            values[13] +
            values[14] +
            values[15] +
            values[16] +
            values[17];
          if (num !== 0) {
            that.operatingProfit[index] = Number(parseFloat(num)).toFixed(2);
          } else {
            that.operatingProfit[index] = '0.00';
          }
        }
        if (index === 2) {
          var num =
            values[0] -
            values[1] -
            values[2] -
            values[3] -
            values[4] -
            values[5] -
            values[6] +
            values[9] +
            values[10] +
            values[13] +
            values[14] +
            values[15] +
            values[16] +
            values[17];
          if (num !== 0) {
            that.operatingProfit[index] = Number(parseFloat(num)).toFixed(2);
          } else {
            that.operatingProfit[index] = '0.00';
          }
        }
      });
      that.$forceUpdate();
      return that.operatingProfit;
    },

    // 单体 利润表 失焦事件
    blur_lr() {
      // 获取利润总额表格
      // console.log(this.$refs.lrze);
      var that = this;
      this.$refs.lrze.columns.forEach((column, index) => {
        if (index === 0) {
          that.TotalProfit[index] = "三、利润总额（亏损总额以“-”号填列）";
          return;
        }
        const values = this.$refs.lrze.data.map((item) => Number(item[column.property]));

        if (index === 1) {
          var num = Number(that.operatingProfit[1]) + Number(values[0]) - Number(values[1]);
          if (num !== 0) {
            that.TotalProfit[index] = Number(parseFloat(num)).toFixed(2);
          } else {
            that.TotalProfit[index] = 0;
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num = Number(that.operatingProfit[2]) + Number(values[0]) - Number(values[1]);
          if (num !== 0) {
            that.TotalProfit[index] = Number(parseFloat(num)).toFixed(2);
          } else {
            that.TotalProfit[index] = 0;
          }
          that.$forceUpdate();
        }
      });
      this.$refs.jlr.columns.forEach((column, index) => {
        if (index === 0) {
          that.retained[index] = "四、净利润（净亏损以“-”号填列）";
          return;
        }
        const values = this.$refs.jlr.data.map((item) => Number(item[column.property]));

        if (index === 1) {
          var num = Number(that.TotalProfit[1]) - Number(values[0]);
          if (num !== 0) {
            that.retained[index] = Number(parseFloat(num)).toFixed(2);
          } else {
            that.retained[index] = 0;
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num = Number(that.TotalProfit[2]) - Number(values[0]);
          if (num !== 0) {
            that.retained[index] = Number(parseFloat(num)).toFixed(2);
          } else {
            that.retained[index] = 0;
          }
          that.$forceUpdate();
        }
      });
    },

    // 三、利润总额
    // getSummariesSeven(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     // console.log(column,index);
    //     if (index === 0) {
    //       that.TotalProfit[index] = "三、利润总额（亏损总额以“-”号填列）";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));

    //     if (index === 1) {
    //       var num = Number(that.operatingProfit[1]) + Number(values[0]) - Number(values[1]);
    //       if (num !== 0) {
    //         that.TotalProfit[index] = Number(parseFloat(num)).toFixed(2);
    //       } else {
    //         that.TotalProfit[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num = Number(that.operatingProfit[2]) + Number(values[0]) - Number(values[1]);
    //       if (num !== 0) {
    //         that.TotalProfit[index] = Number(parseFloat(num)).toFixed(2);
    //       } else {
    //         that.TotalProfit[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.TotalProfit;
    // },
    // getSummarieset(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     // console.log(column,index);
    //     if (index === 0) {
    //       that.retained[index] = "四、净利润（净亏损以“-”号填列）";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));

    //     if (index === 1) {
    //       var num = Number(that.TotalProfit[1]) - Number(values[0]);
    //       if (num !== 0) {
    //         that.retained[index] = Number(parseFloat(num)).toFixed(2);
    //       } else {
    //         that.retained[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num = Number(that.TotalProfit[2]) - Number(values[0]);
    //       if (num !== 0) {
    //         that.retained[index] = Number(parseFloat(num)).toFixed(2);
    //       } else {
    //         that.retained[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.retained;
    // },
    getWrap(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.statement[index] = "(一) 不能重分类进损益的其他综合收益";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          that.statement[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.statement[index];
        } else {
          that.statement[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.statement;
    },
    getSummarieWrap(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.reclassification[index] = "(二) 将重分类进损益的其他综合收益";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.reclassification[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.reclassification[index];
        } else {
          that.reclassification[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.reclassification;
    },


    // 单体现金流量表
    getActivities(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.getActivitiesTotal[index] = "经营活动现金流入小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.getActivitiesTotal[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.getActivitiesTotal[index];
        } else {
          that.getActivitiesTotal[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.getActivitiesTotal;
    },
    getActivitiesOut(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.getActivitiesTotalOut[index] = "经营活动现金流出小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          that.getActivitiesTotalOut[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.getActivitiesTotalOut[index];
        } else {
          that.getActivitiesTotalOut[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.getActivitiesTotalOut;
    },
    investmentTotal(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.investmentTotals[index] = "投资活动现金流入小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          that.investmentTotals[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.investmentTotals[index];
        } else {
          that.investmentTotals[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.investmentTotals;
    },
    investmentTotalOut(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.investmentOut[index] = "投资活动现金流出小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.investmentOut[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.investmentOut[index];
        } else {
          that.investmentOut[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.investmentOut;
    },
    getSummariesRaise(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.getSummariesTotal[index] = "筹资活动现金流入小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.getSummariesTotal[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.getSummariesTotal[index];
        } else {
          that.getSummariesTotal[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.getSummariesTotal;
    },
    getSummariesRaiseOut(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.getSummariesOut[index] = "筹资活动现金流出小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.getSummariesOut[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2)
            } else {
              return prev;
            }
          }, 0);
          that.getSummariesOut[index];
        } else {
          that.getSummariesOut[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.getSummariesOut;
    },

    xjl_zje() {

      var that = this;
      this.$refs.zje.columns.forEach((column, index) => {
        if (index === 0) {
          that.getTexchange[index] = "五、现金及现金等价物净增加额";
          return;
        }
        const values = this.$refs.zje.data.map((item) => Number(item[column.property]));
        if (index === 1) {

          var num = Number(that.getSummariesTotal[1]) - Number(that.getSummariesOut[1]);
          var to = Number(that.investmentTotals[1]) - Number(that.investmentOut[1]);
          var es = Number(that.getActivitiesTotal[1]) - Number(that.getActivitiesTotalOut[1]);

          var total = num + to + es + values[0];
          if (total !== 0) {
            that.getTexchange[index] = parseFloat(total).toFixed(2);
          } else {
            that.getTexchange[index] = '0.00';
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num = Number(that.getSummariesTotal[2]) - Number(that.getSummariesOut[2]);
          var to = Number(that.investmentTotals[2]) - Number(that.investmentOut[2]);
          var es = Number(that.getActivitiesTotal[2]) - Number(that.getActivitiesTotalOut[2]);
          var total = num + to + es + values[0];
          if (!total == 0) {
            that.getTexchange[index] = parseFloat(total).toFixed(2);
          } else {
            that.getTexchange[index] = '0.00';
          }
          that.$forceUpdate();
        }
      });
      // console.log(this.getTexchange);
      this.$refs.ye.columns.forEach((column, index) => {
        if (index === 0) {
          that.getTexchanges[index] = "六、期末现金及现金等价物余额";
          return;
        }
        const values = this.$refs.ye.data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num = Number(that.getTexchange[1]) + Number(values[0]);

          if (num !== 0) {
            that.getTexchanges[index] = parseFloat(num).toFixed(2);
          } else {
            that.getTexchanges[index] = 0;
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num = Number(that.getTexchange[2]) + Number(values[0]);

          if (!num == 0) {
            that.getTexchanges[index] = parseFloat(num).toFixed(2);
          } else {
            that.getTexchanges[index] = 0;
          }
          that.$forceUpdate();
        }
      });
    },

    // geTexchange(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       that.getTexchange[index] = "五、现金及现金等价物净增加额";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));
    //     if (index === 1) {
    //       var num = Number(that.getSummariesTotal[1]) - Number(that.getSummariesOut[1]);
    //       var to = Number(that.investmentTotals[1]) - Number(that.investmentOut[1]);
    //       var es = Number(that.getActivitiesTotal[1]) - Number(that.getActivitiesTotalOut[1]);
    //       var total = num + to + es + values[0];
    //       if (total !== 0) {
    //         that.getTexchange[index] = parseFloat(total).toFixed(2);
    //       } else {
    //         that.getTexchange[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num = Number(that.getSummariesTotal[2]) - Number(that.getSummariesOut[2]);
    //       var to = Number(that.investmentTotals[2]) - Number(that.investmentOut[2]);
    //       var es = Number(that.getActivitiesTotal[2]) - Number(that.getActivitiesTotalOut[2]);
    //       var total = num + to + es + values[0];
    //       if (!total == 0) {
    //         that.getTexchange[index] = parseFloat(total).toFixed(2);
    //       } else {
    //         that.getTexchange[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.getTexchange;
    // },


    // geTexchangesa(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       that.getTexchanges[index] = "六、期末现金及现金等价物余额";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));
    //     if (index === 1) {
    //       var num = Number(that.getTexchange[1]) + Number(values[0]);

    //       if (num !== 0) {
    //         that.getTexchanges[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.getTexchanges[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num = Number(that.getTexchange[2]) + Number(values[0]);

    //       if (!num == 0) {
    //         that.getTexchanges[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.getTexchanges[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.getTexchanges;
    // },
    supplement(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.supplementToken[index] = "经营活动产生的现金流量净额";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.supplementToken[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.supplementToken[index];
        } else {
          that.supplementToken[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.supplementToken;
    },
    supplements(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.supplementsToken[index] = "现金及现金等价物净增加额";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num = Number(values[1]) - Number(values[2]) + Number(values[3]) - Number(values[4]);
          if (!num == 0) {
            that.supplementsToken[index] = parseFloat(num).toFixed(2);
          } else {
            that.supplementsToken[index] = '0.00';
          }
        }
        if (index === 2) {
          var num = Number(values[1]) - Number(values[2]) + Number(values[3]) - Number(values[4]);
          if (!num == 0) {
            that.supplementsToken[index] = parseFloat(num).toFixed(2);
          } else {
            that.supplementsToken[index] = '0.00';
          }
        }
      });
      that.$forceUpdate();
      return that.supplementsToken;
    },

    // 合并资产负债表合计项------------
    getSummariesOnes(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.TotalCurrentAssetss[index] = "流动资产合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.TotalCurrentAssetss[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.TotalCurrentAssetss[index];
        } else {
          that.TotalCurrentAssetss[index] = 0;
        }
      });
      that.$forceUpdate();

      return that.TotalCurrentAssetss;
    },
    getSummariesTwos(param) {
      const { columns, data } = param;
      // const sums = [];
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.TotalCurrentLiabilitys[index] = "流动负债合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.TotalCurrentLiabilitys[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.TotalCurrentLiabilitys[index];
        } else {
          that.TotalCurrentLiabilitys[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.TotalCurrentLiabilitys;
    },
    getSummariesThrees(param) {
      const { columns, data } = param;
      // const sums = [];
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.FlagTotalCurrentAssetss[index] = "非流动资产合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.FlagTotalCurrentAssetss[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.FlagTotalCurrentAssetss[index];
        } else {
          that.FlagTotalCurrentAssetss[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.FlagTotalCurrentAssetss;
    },
    getSummariesFours(param) {
      const { columns, data } = param;
      // const sums = [];
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.FlagTotalCurrentLiabilitys[index] = "非流动负债合计";
          return;
        }
        const values = data.map((item, i) => Number(item[column.property]));
        if (index === 1) {
          var num = values[1] +
            values[2] +
            values[3] +
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10] +
            values[11];
          if (num !== 0) {
            that.FlagTotalCurrentLiabilitys[index] = parseFloat(num).toFixed(2);
          } else {
            that.FlagTotalCurrentLiabilitys[index] = '0.00';
          }
        }
        if (index === 2) {
          var num = values[1] +
            values[2] +
            values[3] +
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10] +
            values[11];
          if (num !== 0) {
            that.FlagTotalCurrentLiabilitys[index] = parseFloat(num).toFixed(2);
          } else {
            that.FlagTotalCurrentLiabilitys[index] = '0.00';
          }
        }
      });
      that.$forceUpdate();
      return that.FlagTotalCurrentLiabilitys;
    },
    getSummariesFives(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        // console.log(column,index);
        if (index === 0) {
          that.ownershipInterests[index] = "所有者权益(或股东权益)合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num = values[12] + values[13];
          if (num !== 0) {
            that.ownershipInterests[index] = parseFloat(num).toFixed(2);
          } else {
            that.ownershipInterests[index] = '0.00';
          }
        }
        if (index === 2) {
          var num = values[12] + values[13];
          if (num !== 0) {
            that.ownershipInterests[index] = parseFloat(num).toFixed(2);
          } else {
            that.ownershipInterests[index] = '0.00';
          }
        }
      });
      that.$forceUpdate();
      return that.ownershipInterests;
    },

    qyhj() {
      this.$refs.syzqy.columns.forEach((column, index) => {
        const values = this.$refs.syzqy.data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num =
            values[1] +
            values[2] +
            values[5] -
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10] +
            values[11]
          this.$refs.syzqy.data[12].value2 = num
        }
        if (index === 2) {
          var num =
            values[1] +
            values[2] +
            values[5] -
            values[6] +
            values[7] +
            values[8] +
            values[9] +
            values[10] +
            values[11]
          this.$refs.syzqy.data[12].value1 = num
        }
      });
    },

    // 合并利润表计算合计  营业利润
    getSummariesSixs(param) {
      
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        // console.log(column,index);
        if (index === 0) {
          that.operatingProfits[index] = "一、营业总收入";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.operatingProfits[index] = values.reduce((prev, curr, i) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.operatingProfits[index];
        } else {
          that.operatingProfits[index] = 0;
        }
      });

      that.$forceUpdate();
      return that.operatingProfits;
    },

    SummarBlur() {
      var that = this;
      this.$refs.yylr.columns.forEach((column, index) => {
        // console.log(column,index);
        if (index === 0) {
          that.retaineds[index] = "三、营业利润";
          return;
        }
        const values = this.$refs.yylr.data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num =
            Number(that.operatingProfits[1]) -
            Number(that.TotalProfits[1]) +
            values[0] +
            values[1] +
            values[4] +
            values[5] +
            values[6] +
            values[7] +
            values[8] +
            values[9];
          if (!num == 0) {
            that.retaineds[index] = parseFloat(num).toFixed(2);
          } else {
            that.retaineds[index] = 0;
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num =
            Number(that.operatingProfits[2]) -
            Number(that.TotalProfits[2]) +
            values[0] +
            values[1] +
            values[4] +
            values[5] +
            values[6] +
            values[7] +
            values[8] +
            values[9];
          if (!num == 0) {
            that.retaineds[index] = parseFloat(num).toFixed(2);
          } else {
            that.retaineds[index] = 0;
          }
          that.$forceUpdate();
        }
      });
      this.$refs.lrze_three.columns.forEach((column, index) => {
        if (index === 0) {
          that.statements[index] = "四、利润总额（亏损总额以“-”号填列）";
          return;
        }
        const values = this.$refs.lrze_three.data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num = Number(that.retaineds[1]) + values[0] - values[1];
          if (num !== 0) {
            that.statements[index] = parseFloat(num).toFixed(2);
          } else {
            that.statements[index] = 0;
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num = Number(that.retaineds[2]) + values[0] - values[1];
          if (num !== 0) {
            that.statements[index] = parseFloat(num).toFixed(2);
          } else {
            that.statements[index] = 0;
          }
          that.$forceUpdate();
        }
      });
      this.$refs.jlr_h.columns.forEach((column, index) => {
        if (index === 0) {
          that.statementsact[index] = "五、净利润（净亏损以“-”号填列）";
          return;
        }
        const values = this.$refs.jlr_h.data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num = Number(that.statements[1]) - values[0];
          if (num !== 0) {
            that.statementsact[index] = parseFloat(num).toFixed(2);
          } else {
            that.statementsact[index] = 0;
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num = Number(that.statements[2]) - values[0];
          if (!num == 0) {
            that.statementsact[index] = parseFloat(num).toFixed(2);
          } else {
            that.statementsact[index] = 0;
          }
          that.$forceUpdate();
        }
      });

    },
    getSummariesSevens(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        // console.log(column,index);
        if (index === 0) {
          that.TotalProfits[index] = "二、营业总成本";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        delete values[13];
        delete values[14];
        if (!values.every((value) => isNaN(value))) {
          that.TotalProfits[index] = values.reduce((prev, curr, i) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.TotalProfits[index];
        } else {
          that.TotalProfits[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.TotalProfits;
    },

    // getSummariesets(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     // console.log(column,index);
    //     if (index === 0) {
    //       that.retaineds[index] = "三、营业利润";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));
    //     if (index === 1) {
    //       var num =
    //         Number(that.operatingProfits[1]) -
    //         Number(that.TotalProfits[1]) +
    //         values[0] +
    //         values[1] +
    //         values[4] +
    //         values[5] +
    //         values[6] +
    //         values[7] +
    //         values[8] +
    //         values[9];
    //       if (!num == 0) {
    //         that.retaineds[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.retaineds[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num =
    //         Number(that.operatingProfits[2]) -
    //         Number(that.TotalProfits[2]) +
    //         values[0] +
    //         values[1] +
    //         values[4] +
    //         values[5] +
    //         values[6] +
    //         values[7] +
    //         values[8] +
    //         values[9];
    //       if (!num == 0) {
    //         that.retaineds[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.retaineds[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.retaineds;
    // },
    // getWraps(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       that.statements[index] = "四、利润总额（亏损总额以“-”号填列）";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));
    //     if (index === 1) {
    //       var num = Number(that.retaineds[1]) + values[0] - values[1];
    //       if (num !== 0) {
    //         that.statements[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.statements[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num = Number(that.retaineds[2]) + values[0] - values[1];
    //       if (num !== 0) {
    //         that.statements[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.statements[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.statements;
    // },
    // getSummarieWraps(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       that.statementsact[index] = "五、净利润（净亏损以“-”号填列）";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));
    //     if (index === 1) {
    //       var num = Number(that.statements[1]) - values[0];
    //       if (num !== 0) {
    //         that.statementsact[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.statementsact[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num = Number(that.statements[2]) - values[0];
    //       if (!num == 0) {
    //         that.statementsact[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.statementsact[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.statementsact;
    // },
    getSummarieClass(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.SummarieClass[index] = "（一）按经营持续性分类";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.SummarieClass[index] = values.reduce((prev, curr, i) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.SummarieClass[index];
        } else {
          that.SummarieClass[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.SummarieClass;
    },
    getSummarieClasss(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.SummarieClasss[index] = "（二）按所有权归属分类";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.SummarieClasss[index] = values.reduce((prev, curr, i) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.SummarieClasss[index];
        } else {
          that.SummarieClasss[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.SummarieClasss;
    },
    getNoClass(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.noClass[index] = "1.不能重分类进损益的其他综合收益";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.noClass[index] = values.reduce((prev, curr, i) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.noClass[index];
        } else {
          that.noClass[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.noClass;
    },
    getNoClasss(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.noClasss[index] = "2.将重分类进损益的其他综合收益";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.noClasss[index] = values.reduce((prev, curr, i) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.noClasss[index];
        } else {
          that.noClasss[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.noClasss;
    },

    // 合并现金流量表
    getActivitiess(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.getActivitiesTotals[index] = "经营活动现金流入小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          that.getActivitiesTotals[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.getActivitiesTotals[index];
        } else {
          that.getActivitiesTotals[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.getActivitiesTotals;
    },
    getActivitiesOuts(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.getActivitiesTotalOuts[index] = "经营活动现金流出小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          that.getActivitiesTotalOuts[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.getActivitiesTotalOuts[index];
        } else {
          that.getActivitiesTotalOuts[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.getActivitiesTotalOuts;
    },
    investmentTotalty(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.investmentTotalss[index] = "投资活动现金流入小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        if (!values.every((value) => isNaN(value))) {
          that.investmentTotalss[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.investmentTotalss[index];
        } else {
          that.investmentTotalss[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.investmentTotalss;
    },
    investmentTotalOuts(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.investmentOuts[index] = "投资活动现金流出小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          that.investmentOuts[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let num = Number(prev) + Number(curr)
              return parseFloat(num).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
          that.investmentOuts[index];
        } else {
          that.investmentOuts[index] = 0;
        }
      });
      that.$forceUpdate();
      return that.investmentOuts;
    },

    getSummariesRaises(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index === 0) {
          that.getSummariesTotals[index] = "筹资活动现金流入小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (index == 1) {
          var num = values[1] + values[3] + values[4];
          if (num !== 0) {
            that.getSummariesTotals[index] = parseFloat(num).toFixed(2);
          } else {
            that.getSummariesTotals[index] = '0.00';
          }
          that.$forceUpdate();
        }
        if (index == 2) {
          var num = values[1] + values[3] + values[4];
          if (num !== 0) {
            that.getSummariesTotals[index] = parseFloat(num).toFixed(2);
          } else {
            that.getSummariesTotals[index] = '0.00';
          }
          that.$forceUpdate();
        }
      });
      that.$forceUpdate();
      return that.getSummariesTotals;
    },
    getSummariesRaiseOuts(param) {
      const { columns, data } = param;
      var that = this;
      columns.forEach((column, index) => {
        if (index == 0) {
          that.getSummariesOuts[index] = "筹资活动现金流出小计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (index == 1) {
          var num = values[0] + values[1] + values[3];
          if (num !== 0) {
            that.getSummariesOuts[index] = parseFloat(num).toFixed(2);
          } else {
            that.getSummariesOuts[index] = '0.00';
          }
          that.$forceUpdate();
        }
        if (index == 2) {
          var num = values[0] + values[1] + values[3];
          if (num !== 0) {
            that.getSummariesOuts[index] = parseFloat(num).toFixed(2);
          } else {
            that.getSummariesOuts[index] = '0.00';
          }
          that.$forceUpdate();
        }
      });
      that.$forceUpdate();
      return that.getSummariesOuts;
    },

    xjl_bl() {
      var that = this;
      this.$refs.wu_zje.columns.forEach((column, index) => {
        if (index === 0) {
          that.getTexchanges[index] = "五、现金及现金等价物净增加额";
          return;
        }
        const values = this.$refs.wu_zje.data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num =
            values[0] +
            Number(that.getSummariesTotals[1]) -
            Number(that.getSummariesOuts[1]) +
            Number(that.investmentTotalss[1]) -
            Number(that.investmentOuts[1]) +
            Number(that.getActivitiesTotals[1]) -
            Number(that.getActivitiesTotalOuts[1]);
          if (!num == 0) {
            that.getTexchanges[index] = parseFloat(num).toFixed(2);
          } else {
            that.getTexchanges[index] = '0.00';
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num =
            values[0] +
            Number(that.getSummariesTotals[2]) -
            Number(that.getSummariesOuts[2]) +
            Number(that.investmentTotalss[2]) -
            Number(that.investmentOuts[2]) +
            Number(that.getActivitiesTotals[2]) -
            Number(that.getActivitiesTotalOuts[2]);
          if (!num == 0) {
            that.getTexchanges[index] = parseFloat(num).toFixed(2);
          } else {
            that.getTexchanges[index] = '0.00';
          }
          that.$forceUpdate();
        }
      });
      // console.log(this.getTexchanges);
      this.$refs.qcye.columns.forEach((column, index) => {
        if (index === 0) {
          that.getTexchangess[index] = "六、期末现金及现金等价物余额";
          return;
        }
        const values = this.$refs.qcye.data.map((item) => Number(item[column.property]));
        if (index === 1) {
          var num = Number(that.getTexchanges[1]) + values[0];

          if (!num == 0) {
            that.getTexchangess[index] = parseFloat(num).toFixed(2);;
          } else {
            that.getTexchangess[index] = '0.00';
          }
          that.$forceUpdate();
        }
        if (index === 2) {
          var num = Number(that.getTexchanges[2]) + values[0];

          if (!num == 0) {
            that.getTexchangess[index] = parseFloat(num).toFixed(2);;
          } else {
            that.getTexchangess[index] = '0.00';
          }
          that.$forceUpdate();
        }
      });
    },

    // geTexchanges(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       that.getTexchanges[index] = "五、现金及现金等价物净增加额";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));
    //     if (index === 1) {
    //       var num =
    //         values[0] +
    //         Number(that.getSummariesTotals[1]) -
    //         Number(that.getSummariesOuts[1]) +
    //         Number(that.investmentTotalss[1]) -
    //         Number(that.investmentOuts[1]) +
    //         Number(that.getActivitiesTotals[1]) -
    //         Number(that.getActivitiesTotalOuts[1]);
    //       if (!num == 0) {
    //         that.getTexchanges[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.getTexchanges[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num =
    //         values[0] +
    //         Number(that.getSummariesTotals[2]) -
    //         Number(that.getSummariesOuts[2]) +
    //         Number(that.investmentTotalss[2]) -
    //         Number(that.investmentOuts[2]) +
    //         Number(that.getActivitiesTotals[2]) -
    //         Number(that.getActivitiesTotalOuts[2]);
    //       if (!num == 0) {
    //         that.getTexchanges[index] = parseFloat(num).toFixed(2);
    //       } else {
    //         that.getTexchanges[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.getTexchanges;
    // },
    // geTexchangess(param) {
    //   const { columns, data } = param;
    //   var that = this;
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       that.getTexchangess[index] = "六、期末现金及现金等价物余额";
    //       return;
    //     }
    //     const values = data.map((item) => Number(item[column.property]));
    //     if (index === 1) {
    //       var num = Number(that.getTexchanges[1]) + values[0];

    //       if (!num == 0) {
    //         that.getTexchangess[index] = parseFloat(num).toFixed(2);;
    //       } else {
    //         that.getTexchangess[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //     if (index === 2) {
    //       var num = Number(that.getTexchanges[2]) + values[0];

    //       if (!num == 0) {
    //         that.getTexchangess[index] = parseFloat(num).toFixed(2);;
    //       } else {
    //         that.getTexchangess[index] = '0.00';
    //       }
    //       that.$forceUpdate();
    //     }
    //   });
    //   that.$forceUpdate();
    //   return that.getTexchangess;
    // },

    // 取消返回
    back() {
      this.$router.push("/reportManagement");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table__footer-wrapper tbody td {
  background-color: #fff !important;
}

/deep/.el-input-number .el-input__inner {
  text-align: left !important;
}

#handMadeFrom {
  padding-bottom: 50px;

  .tablesBox {
    /deep/.el-table__header-wrapper {
      height: 0px;
    }
  }

  #box {
    position: relative;
    margin-top: 40px;
    padding: 40px 60px 60px 60px;
    background: #ebf2f8;

    .ManageTop,
    .ManageBottom {
      background: #fff;
      border-radius: 4px;
      position: relative;

      .elselect {
        text-align: right;
        // margin-bottom: -35px;
        position: absolute;
        top: 23px;
        right: 40px;
        z-index: 9;
      }
    }

    .ManageTop {
      padding: 25px;

      // height: 300px;
      .title {
        font-size: 18px;
        font-weight: 550;
      }

      .hardTop {
        margin-top: 30px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        justify-content: space-between;
        padding-right: 50px;

        .TitleText {
          padding-right: 10px;
          font-size: 16px;
          color: #333;
        }

        .box {
          display: flex;
          align-items: center;
        }
      }
    }

    .ManageBottom {
      margin-top: 20px;
      padding: 20px 40px 40px 40px;

      .count {
        width: 100%;
        height: 52px;
        // background: #f5f7fa;
        padding: 15px 8px;
        font-weight: 600;
        color: #000;
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        text-align: left;

        :nth-child(1) {
          padding-left: 3px;
        }

        :nth-child(2) {
          padding-left: 18px;
        }

        :nth-child(3) {
          padding-left: 22px;
        }
      }

      /deep/.el-form-item {
        margin: 0 !important;
      }

      /deep/.el-form-item__content {
        margin: 0 !important;
      }

      /deep/.el-table td,
      .el-table th {
        padding: 5px 0px;
      }

      ::v-deep .el-table .el-table__footer-wrapper .cell {
        font-weight: 600;
        color: #000;
        height: 40px;
        line-height: 45px;
      }
    }
  }

  .flexBottom {
    background: #fff;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    padding-right: 60px;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    z-index: 999;
  }

  .contentInfoWrap /deep/ .el-table {
    display: flex;
    flex-direction: column;
  }

  /deep/ .contentInfoWrap .el-table__body-wrapper {
    order: 1;
  }

  /deep/ .contentInfoWrap .el-table__fixed-body-wrapper {
    top: 97px !important;
  }

  /deep/.contentInfoWrap .el-table__fixed-footer-wrapper {
    z-index: 0;
    top: 50px;
  }

  /deep/ .el-dialog__footer {
    text-align: center;
  }

  /deep/.el-input.is-disabled .el-input__inner {
    color: #333 !important
  }
}
</style>